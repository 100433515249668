const getLocalStorageData = key => {
  const data = localStorage.getItem(key)
  return typeof data === 'string' ? data : JSON.parse(data)
}

const setLocalStorageData = (key, value) => {
  const stringData = typeof value === 'string' ? value : JSON.stringify(value)
  localStorage.setItem(key, stringData)
}

const removeLocalStorageData = key => {
  localStorage.removeItem(key)
}

export {getLocalStorageData, setLocalStorageData, removeLocalStorageData}
