import { Api } from '../Api/Api'
import { config } from '../Api/config'
const GetAssetStatusesApi = async params => {
  try {
    const response = await Api.getRequestApi(config.STS_STATUSES_URL)
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetTierWiseAssetCategorizationSummaryDMSAPI = async params => {
  try {
    const response = await Api.getRequestApi(config.STS_DMS)
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetPagedAssetsAPI = async params => {
  try {
    const response = await Api.postRequestApi(
      config.STS_PAGED_ASSETS_URL,
      params,
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}


const GetPagedAssetsRentalRAPI = async params => {
  try {
    const response = await Api.postRequestApi(
      config.STS_PAGED_ASSETS_RENTAL_URL,
      params,
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetPagedCurrentRentalAPI = async (params) => {
  try {
    const response = await Api.getRequestApi(
      (`${config.STS_CONTRACT_URL}?contractId=${params}`),
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetPagedCurrentRentalImgAPI = async (params) => {
  try {
    const response = await Api.getRequestApi(
      (`${config.PICTURES_URL}?contextId=${params}&contextTypeName=Asset`),
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetSettingByKey = async params => {
  try {
    const response = await Api.getRequestApi(config.SETTING_KEY)
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}


const GetStoresAPI = async params => {
  try {
    const response = await Api.getRequestApi(config.sts_GETSTARES)
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

const GetPagedDeliveryReceiptSummaryAPI = async params => {
  try {
    const response = await Api.postRequestApi(
      config.STS_SUMMARY_URL,
      params,
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return { error }
  }
}

export const dashboardService = {
  GetAssetStatusesApi,
  GetTierWiseAssetCategorizationSummaryDMSAPI,
  GetPagedAssetsAPI,
  GetPagedAssetsRentalRAPI,
  GetPagedDeliveryReceiptSummaryAPI,
  GetSettingByKey,
  GetStoresAPI,
  GetPagedCurrentRentalAPI,
  GetPagedCurrentRentalImgAPI,
}
