import Axios from 'axios'
import {ACCESS_TOKENS} from '../Utils/contasts'
import {getLocalStorageData} from '../Utils/helpers'

Axios.interceptors.request.use(async config => {
  const token = getLocalStorageData(ACCESS_TOKENS)

  config.headers = {
    'Authorization':"Bearer " + token,
    "accept": "application/json",
    'Content-Type': 'application/json',
    ...config.headers,
  }
  config.timeout = 30000
  return config
})

const getRequestApi = url => {
  return new Promise((resolve, reject) => {
    Axios.get(url)
      .then(result => {
        resolve({
          errorStatus: false,
          statusCode: result.status,
          data: result.data,
        })
      })
      .catch(error => {
        if (error?.response) {
          resolve({
            errorStatus: true,
            statusCode: error?.response.status,
            data: error?.response.data,
          })
        } else {
          reject(error)
        }
      })
  })
}

const postRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    Axios.post(url, data, {
      headers: headers,
    })
      .then(result => {
        resolve({
          errorStatus: false,
          statusCode: result.status,
          data: result.data,
          length:result?.headers?.['x-pagination'] ? JSON.parse(result?.headers?.['x-pagination']):null,
        })
      })
      .catch(error => {
        if (error?.response) {
          resolve({
            errorStatus: true,
            statusCode: error?.response.status,
            data: error?.response.data,
          })
        } else {
          reject(error)
        }
      })
  })
}

const patchRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    Axios.patch(url, data, {
      headers: headers,
    })
      .then(result => {
        resolve({
          errorStatus: false,
          statusCode: result.status,
          data: result.data,
        })
      })
      .catch(error => {
        if (error?.response) {
          resolve({
            errorStatus: true,
            statusCode: error?.response.status,
            data: error?.response.data,
          })
        } else {
          reject(error)
        }
      })
  })
}

const putRequestApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    Axios.put(url, data, {
      headers: headers,
    })
      .then(result => {
        resolve({
          errorStatus: false,
          statusCode: result.status,
          data: result.data,
        })
      })
      .catch(error => {
        if (error?.response) {
          resolve({
            errorStatus: true,
            statusCode: error?.response.status,
            data: error?.response.data,
          })
        } else {
          reject(error)
        }
      })
  })
}

const deleteRequestApi = (url, data) => {
  return new Promise((resolve, reject) => {
    Axios.delete(url, {
      data,
    })
      .then(result => {
        resolve({
          errorStatus: false,
          statusCode: result.status,
          data: result.data,
        })
      })
      .catch(error => {
        if (error?.response) {
          resolve({
            errorStatus: true,
            statusCode: error?.response.status,
            data: error?.response.data,
          })
        } else {
          reject(error)
        }
      })
  })
}

export const Api = {
  getRequestApi,
  postRequestApi,
  patchRequestApi,
  putRequestApi,
  deleteRequestApi,
}
