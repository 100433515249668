import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeComponent from "../../Component/Home/HomeComponent";
import { SELECT_BOARD } from "../../Utils/contasts";
import { getLocalStorageData } from "../../Utils/helpers";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    checkBOARD();
  }, []);

  const checkBOARD = async () => {
    let BOARD = getLocalStorageData(SELECT_BOARD);
    if (BOARD === "delivery-receipt") {
      navigate("/delivery-receipt");
    }

    if (BOARD === "dashboard") {
      navigate("/dashboard");
    }

    if (BOARD === "dashboard-v2") {
      navigate("/dashboard-v2");
    }
  };
  return (
    <>
      <Box className="Home_Bg">
        <HomeComponent />
      </Box>
    </>
  );
};
export default Home;
