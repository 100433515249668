import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import LoginComponent from '../../Component/Login/LoginComponent'
import {ACCESS_TOKENS} from '../../Utils/contasts'
import {getLocalStorageData, getToken} from '../../Utils/helpers'

export default function Login() {
  const navigate = useNavigate()

  useEffect(() => {
    checkLocalToken()
  }, [])

  const checkLocalToken = async () => {
    let token = getLocalStorageData(ACCESS_TOKENS)
    if (!!token) {
      navigate('/home')
    }
  }

  return (
    <>
      <LoginComponent />
    </>
  )
}
