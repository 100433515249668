import DashboardV2Component from "../../Component/Dashboard-V2/DashboardV2Component";

const DashboardV2 = () => {
  return (
    <>
        <DashboardV2Component />
    </>
  );
};
export default DashboardV2;
