import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useNavigate} from 'react-router-dom'
import {validateEmail, validatePassword} from '../../Utils/validators'
import {authService} from '../../Services/authService'
import {ACCESS_TOKENS} from '../../Utils/contasts'
import {setLocalStorageData} from '../../Utils/helpers'
import CustomLoader from '../CustomLoader/customLoader'

import {toast} from 'react-toastify'

const LoginComponent = () => {
  const navigate = useNavigate()
  const [rememberMe, setRememberMe] = React.useState(
    JSON.parse(localStorage.getItem('rememberMe'))?.rememberMe ?? false,
  )
  const [email, setEmail] = React.useState(
    JSON.parse(localStorage.getItem('rememberMe'))?.email ?? '',
  )
  const [emailErr, setEmailErr] = React.useState('')
  const [password, setPassword] = React.useState(
    JSON.parse(localStorage.getItem('rememberMe'))?.password ?? '',
  )
  const [passwordErr, setPasswordErr] = React.useState('')
  const [isBtnDisable, setIsBtnDisable] = React.useState(true)
  const [loading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (email.length > 0 && password.length > 0 && !emailErr && !passwordErr) {
      setIsBtnDisable(false)
    } else {
      setIsBtnDisable(true)
    }
  }, [email, password, emailErr, passwordErr])

  const handleEmail = value => {
    const {msg} = validateEmail(value.trim())
    setEmail(value)
    setEmailErr(msg)
  }

  const handlePassword = value => {
    const {msg} = validatePassword(value.trim())
    setPassword(value)
    setPasswordErr(msg)
  }

  const handleSubmit = async e => {
    if (!!email && !!password) {
      // Login Api Params
      const params = {
        grant_type: 'password',
        username: email,
        password: password,
        client_id: process.env.REACT_APP_STS_CLIENT_ID,
        client_secret: process.env.REACT_APP_STS_CLIENT_SECRET,
        scope: process.env.REACT_APP_STS_SCOPE,
        ApplicationId: process.env.REACT_APP_APPLICATION_ID,
      }
      setIsLoading(true)
      authService
        .loginApi(params)
        .then(response => {
          setIsLoading(false)
          if (response.statusCode === 200) {
            setLocalStorageData(ACCESS_TOKENS, response.data.access_token)
            if (rememberMe) {
              let rememberMe = {
                email: email,
                password: password,
                rememberMe: true,
              }
              localStorage.setItem('rememberMe', JSON.stringify(rememberMe))
            } else {
              localStorage.removeItem('rememberMe')
            }
            navigate('/home')
          } else {
            toast.error(response.data.error_description)
          }
        })
        .catch(error => console.log('Login Api Error =====', error))
    }
    e.preventDefault()
  }

  return (
    <Box className="Login_Page_Box">
      {loading ? (
        <Box
          sx={{
            height: '90vh',
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomLoader />
        </Box>
      ) : (
        <Box
          className="Login_Box"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{m: 1}}>
            <img src="./logo-dark.png" alt="" width={'80px'} />
          </Box>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              error={!!emailErr}
              helperText={emailErr}
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={e => handleEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              error={!!passwordErr}
              helperText={passwordErr}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={e => handlePassword(e.target.value)}
            />

            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={rememberMe}
                      onClick={() => setRememberMe(!rememberMe)}
                    />
                  }
                  label="Remember me"
                />
              </Grid>
              <Grid item xs></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              disabled={isBtnDisable}
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default LoginComponent
