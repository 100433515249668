import React from 'react';
import {  Navigate } from 'react-router-dom';
import { ACCESS_TOKENS } from '../Utils/contasts';
import { getLocalStorageData } from '../Utils/helpers';

const ProtectedRoute = ({ Component, AddCloudComponent }) => {
  const token = getLocalStorageData(ACCESS_TOKENS)
  return (
    token != null ? <Component /> : <Navigate to="/" />
  );

}
export default ProtectedRoute;