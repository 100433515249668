import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { SELECT_BOARD } from "../../Utils/contasts";
import { setLocalStorageData } from "../../Utils/helpers";

const HomeComponent = () => {
  const navigate = useNavigate();

  const Dashboard_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "dashboard");
    navigate("/dashboard")
  }

  const Dashboard_Navigate_v2 = () => {
    setLocalStorageData(SELECT_BOARD, "dashboard-v2");
    navigate("/dashboard-v2")
  }

  const Delivery_Receipt_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "delivery-receipt");
    navigate("/delivery-receipt")
  }
  return (
    <>
      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{justifyContent: "center"}}>
            <Grid item xs={12} md={6}>

              <Box
                className="Home_Box">
                <Card
                  sx={{ minWidth: 275 }}
                  onClick={Dashboard_Navigate}
                  className="Home_Card"
                >

                  <img src="logo-dark.png" alt="" width={'80px'} className="Home_Card_Logo" />
                  <CardContent>
                    <h1>Rental Board</h1>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>

              <Box
                className="Home_Box">
                <Card
                  sx={{ minWidth: 275 }}
                  onClick={Dashboard_Navigate_v2}
                  className="Home_Card"
                >

                  <img src="logo-dark.png" alt="" width={'80px'} className="Home_Card_Logo" />
                  <CardContent>
                    <h1>Rental Board V2</h1>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} mt={"50px"}>
              <Box
                className="Home_Box">
                <Card
                  sx={{ minWidth: 275 }}
                  onClick={Delivery_Receipt_Navigate}
                  className="Home_Card"
                >
                  <img src="logo-dark.png" alt="" width={'80px'} className="Home_Card_Logo" />
                  <CardContent>
                    <h1>Delivery Receipt</h1>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default HomeComponent;
