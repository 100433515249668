import {Api} from '../Api/Api'
import {config} from '../Api/config'

const header = {
  'Content-Type': 'application/x-www-form-urlencoded',
}

const loginApi = async params => {
  try {
    const response = await Api.postRequestApi(
      config.STS_SIGNIN_URL,
      params,
      header,
    )
    return response
  } catch (error) {
    // utils.errorAlert(error, () => {}, false)
    return {error}
  }
}

export const authService = {loginApi}
