const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
  if (!email) {
    return { msg: "Email is required" };
  }
  if (!emailRegex.test(email)) {
    return { msg: "Please enter valid email" };
  }
  return { msg: "" };
};

const validatePassword = (password) => {
  if (!password) {
    return { msg: "Password is required" };
  }
  if (password.length < 6) {
    return { msg: "Password must be at least 6 characters" };
  }
  return { msg: "" };
};

export { validateEmail, validatePassword };
