/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import PauseIcon from "@mui/icons-material/Pause";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import GoogleMapReact from "google-map-react";
import LogOutIcon from "../../asset/icon/logout-icon.svg";
import RNONE from "../../asset/icon/Rental-Board-v1.svg";
import RNTWO from "../../asset/icon/Rental-Board-v2.svg";
import DeliveryReceipt from "../../asset/icon/Delivery-Receipt.svg";
import RentalBord from "../../asset/icon/Rental-Board.svg";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  checkboxClasses,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { dashboardService } from "../../Services/dashboardService";
import { useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CustomLoader from "../CustomLoader/customLoader";
import { setLocalStorageData } from "../../Utils/helpers";
import { SELECT_BOARD } from "../../Utils/contasts";
import moment from "moment/moment";
import { styled } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const showInfoStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  maxWidth: "60vw",
  overflow: "auto",
  border: "0px solid",
  outline: "none",
};

const AnyReactComponent = ({
  text,
  item,
  onClick,
  selectPoPupMarker,
  closePopUp,
  blueImg,
}) => (
  <div className="Map_Pin_Div">
    {!blueImg &&
      selectPoPupMarker?.PositionLatitude === item?.PositionLatitude &&
      selectPoPupMarker?.PositionLongitude === item?.PositionLongitude ? (
      <div class="help-tip">
        <p>
          <div className="custom_pop_up">
            <div className="custom_pop_up_close_icon_box">
              {" "}
              <img
                src="./close-small.png"
                alt=""
                className="custom_pop_up_close_icon"
                onClick={closePopUp}
              />
            </div>
            <div className="custom_pop_up_head_1">
              {selectPoPupMarker?.CompanyName}
            </div>
            {/* <div className="custom_pop_up_head_2">Location Name : ""</div> */}
            {/* <div className="custom_pop_up_head_3">Address : ""</div> */}
            <div className="custom_pop_up_head_3">
              Year: {selectPoPupMarker?.Year}
            </div>
            <div className="custom_pop_up_head_3">
              MakeName: {selectPoPupMarker?.MakeName}{" "}
            </div>
            <div className="custom_pop_up_head_3">
              Model: {selectPoPupMarker?.Model}
            </div>
            <div className="custom_pop_up_head_3">
              SerialNumber: {selectPoPupMarker?.SerialNumber}
            </div>
            <div className="custom_pop_up_head_3">
              PositionDateTime:{" "}
              {moment(selectPoPupMarker?.PositionDateTime).format("llll")}
            </div>
          </div>
        </p>
      </div>
    ) : (
      ""
    )}
    <p className="Map_Pin_Img_Text">{item?.AssetDescription}</p>
    <div onClick={onClick}>
      {blueImg ? (
        <img
          src="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
          alt=""
          width={"48px"}
          height={"48px"}
        />
      ) : moment(new Date().toJSON()).diff(
        moment(item?.PositionDateTime),
        "days"
      ) <= 7 ? (
        <img
          src="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          alt=""
          width={"48px"}
          height={"48px"}
        />
      ) : (
        <img
          src="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          alt=""
          width={"48px"}
          height={"48px"}
        />
      )}
    </div>
  </div>
);

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

const DashboardV2Component = () => {
  const theme = useTheme();
  const mapDefaultZoom = 5;
  const [showDetails, setShowDetails] = React.useState({
    info: null,
    model: false,
  });
  const [selectPoPupMarker, setSelectPoPupMarker] = React.useState();
  const [selectRentedMapItem, setSelectRentedMapItem] = React.useState({
    lat: "",
    lng: "",
    zoom: mapDefaultZoom,
  });
  const [autoNextPageMap, setAutoNextPageMap] = React.useState(true);
  const [rentedMapDataCurrontPage, setRentedMapDataCurrontPage] =
    React.useState(1);
  const [intervalIdMap, setIntervalIdMap] = React.useState(null);
  const [rentedMapDataLength, setRentedMapDataLength] = React.useState(0);
  const [rentedMap, setRentedMap] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState(null);
  const [settingValue, setSettingValue] = React.useState(null);
  const [totalAssetsRecode, setTotalAssetsRecode] = React.useState({});
  const [settingValueLoader, setSettingValueLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectStatuesModel, setSelectStatuesModel] = React.useState(false);
  const [storeList, setStoreList] = React.useState([]);
  const [contractInfo, setContractInfo] = React.useState();
  const [contractImgInfo, setContractImgInfo] = React.useState();
  const [contractInfoLoader, setContractInfoLoader] = React.useState(false);
  const [showParticularCategory, setShowParticularCategory] = React.useState({
    categoryID: null,
    categoryName: null,
    showOnlyOneCategory: false,
  });
  const [navigationColorColor, setNavigationColorColor] = React.useState([
    {
      color: "#863B96",
      use: false,
    },
    {
      color: "#00b33c",
      use: false,
    },
    {
      color: "#FFC80F",
      use: false,
    },
    {
      color: "#ac7339",
      use: false,
    },
    {
      color: "#1a75ff",
      use: false,
    },
    {
      color: "#fccde2",
      use: false,
    },
    {
      color: "#a7ff83",
      use: false,
    },
    {
      color: "#88bef5",
      use: false,
    },
    {
      color: "#e8ffe8",
      use: false,
    },
    {
      color: "#a56cb4",
      use: false,
    },
    {
      color: "#775ada",
      use: false,
    },
    {
      color: "#278ea5",
      use: false,
    },
    {
      color: "#ffb174",
      use: false,
    },
  ]);
  const navigate = useNavigate();
  const [showAssetCount, setShowAssetCount] = React.useState(
    localStorage.getItem("rowPerPage") ?? 10
  );

  const [showAssetCountMap, setShowAssetCountMap] = React.useState(
    localStorage.getItem("rowPerPage-Rental-Map") ?? 200
  );

  const [searchString, setSearchString] = React.useState();

  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [storeCodeSelect, setStoreCodeSelect] = React.useState(
    localStorage.getItem("StoreCode") ?? ""
  );

  const [autoNextPage, setAutoNextPage] = React.useState(true);
  const [timeAutoNextPage, setTimeAutoNextPage] = React.useState(false);
  const [
    assetCategorizationSummaryDMSLoader,
    setAssetCategorizationSummaryDMSLoader,
  ] = React.useState(true);
  const [loader, setLoader] = React.useState({
    AssetCategorizationSummaryDMSLoader: true,
    AssetStatusesLoader: true,
  });
  const [openSwitchModel, setOpenSwitchModel] = React.useState(false);

  const [openSwitchV1Model, setOpenSwitchV1Model] = React.useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [sideBarMenu, setSideBarMenu] = React.useState(false);
  const [assetList, setAssetList] = React.useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });

  const [dashboardLoader, setDashboardLoader] = React.useState(true);
  const [AssetTypeList, setAssetTypeList] = React.useState([]);
  const [assetStatuses, setAssetStatuses] = React.useState(
    JSON.parse(localStorage.getItem("DisplayStatues-V2")) ?? []
  );
  const [assetDisplayStatues, setAssetDisplayStatues] = React.useState([]);
  const [assetStatusesDetails, setAssetStatusesDetails] = React.useState([]);
  const [assetStatusesListPagination, setAssetStatusesListPagination] =
    React.useState([]);
  const [showSideBar, setShowSideBar] = React.useState(true);
  const [DisplayStatuesLocalStorage, setDisplayStatuesLocalStorage] =
    React.useState(JSON.parse(localStorage.getItem("DisplayStatues-V2")));

  const [onRentMashinListings, setOnRentMashinListings] = React.useState([]);
  const [onRentMashinListingsLoading, setOnRentMashinListingsLoading] =
    React.useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AssetList = [
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["Available"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
  ];

  React.useEffect(() => {
    GetSettingByKey();
  }, []);

  const GetSettingByKey = async () => {
    setSettingValueLoader(true);
    setDashboardLoader(true);
    dashboardService
      .GetSettingByKey()
      .then((response) => {
        if (response.statusCode === 200) {
          setSettingValueLoader(false);
          setSettingValue(response?.data?.SettingValue.split(","));
        } else {
          setSettingValueLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const ITEM_HEIGHT = 300;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const DashboardCardBox = ({ item, navigationColor, onClick, className }) => {
    return (
      <Box
        className={`Dashboard_Asset_Card ${className}`}
        onClick={() => {
          setShowDetails({
            info: item,
            model: true,
          });
          onClick(item);
        }}
        sx={{ position: "relative", cursor: "pointer" }}
      >
        {item.StoreCode && item?.StoreColourCode && (
          <Box
            sx={{
              position: "absolute",
              background: item?.StoreColourCode,
              borderRadius: "50%",
              bottom: "-10px",
              height: "36px",
              width: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              left: "-10px",
            }}
          >
            {item.StoreCode}
          </Box>
        )}
        <Grid container spacing={0}>
          <Grid
            item
            xs={0.5}
            sx={{
              p: "5px",
              background: item?.AssetStatusColour ?? "#c3c3c3",
              borderRadius: "5px 0 0 5px",
            }}
          ></Grid>
          <Grid item xs={6.5} sx={{ p: "5px" }}>
            {/* <Tooltip title={item?.CompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.CompanyName}
              </Box>
            </Tooltip> */}

            <Tooltip title={item?.UnitNumber} placement="top">
              <Box className="Dashboard_Asset_Card_Text Bold_text oneLineCSS">
                {item?.UnitNumber || "-"}
              </Box>
            </Tooltip>
            <Tooltip title={item?.MakeName + item?.Model} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.MakeName + " " + item?.Model || "-"}
              </Box>
            </Tooltip>

            {/* <Tooltip title={item?.Year} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.Year || ''}
              </Box>
            </Tooltip> */}

            <Tooltip title={item?.SerialNumber} placement="top">
              <Box className="Dashboard_Asset_Card_Text oneLineCSS">
                {item?.SerialNumber || "-"}
              </Box>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ background: "#f5f5f5", p: "5px" }}
            className="Dashboard_Asset_Card_Text_Highlight"
          >
            <Tooltip title={item?.CompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                {item?.CompanyName}
              </Box>
            </Tooltip>
            {item?.OnOpenRental === 1 ? (
              <>
                <Tooltip
                  title={item?.CurrentRentalCustomerName}
                  placement="top"
                >
                  <Box
                    className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                    style={{ textAlign: "left" }}
                  >
                    {item?.CurrentRentalCustomerName}
                  </Box>
                </Tooltip>
                <Tooltip title={item?.CurrentRentalContract} placement="top">
                  <Box
                    className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                    style={{ textAlign: "left" }}
                  >
                    {item?.CurrentRentalContract}
                  </Box>
                </Tooltip>
                {item?.ExpectedRentalReturnDate ? (
                  <Tooltip
                    title={moment(item?.ExpectedRentalReturnDate).format("ll")}
                    placement="top"
                  >
                    <Box
                      className="Dashboard_Asset_Card_Test_Color oneLineCSS"
                      style={{ textAlign: "left" }}
                    >
                      {moment(item?.ExpectedRentalReturnDate).format("ll")}
                    </Box>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const DashboardBigCardBox = ({
    item,
    navigationColor,
    onClick,
    className,
  }) => {
    const location = `${item?.Address1 || ""}  ${item?.Address2 || ""} ${item?.City || ""
      }  ${item?.Province || ""} ${item?.Country || ""} ${item?.PostalCode || ""} ${!item?.Address1 && !item?.Address2 && !item?.City && !item?.Province && !item?.Country && !item?.PostalCode ? "-" : ""}`;


    const CompanyLocation = `${item?.LocationAddress1 ? item?.LocationAddress1 : ''}
      ${item?.LocationAddress2
        ? `, ${item?.LocationAddress2}`
        : ""}
      ${item?.LocationCity
        ? `, ${item?.LocationCity}`
        : ""}
      ${item?.LocationProvince
        ? `, ${item?.LocationProvince}`
        : ""}
      ${item?.LocationPostalCode
        ? `, ${item?.LocationPostalCode}`
        : ""}   ${!item?.LocationAddress1 && !item?.LocationAddress2 && !item?.LocationCity && !item?.LocationPostalCode ? "-" : ""}`

    return (
      <Box
        className={`Dashboard_Asset_Card ${className}`}
        width={"650px"}
        onClick={() => {
          setShowDetails({
            info: item,
            model: true,
          });
          onClick(item);
        }}
        sx={{ position: "relative", cursor: "pointer" }}
      >
        {item.StoreCode && item?.StoreColourCode && (
          <Box
            sx={{
              position: "absolute",
              background: item?.StoreColourCode,
              borderRadius: "50%",
              bottom: "-10px",
              height: "36px",
              width: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              left: "-10px",
            }}
          >
            {item.StoreCode}
          </Box>
        )}
        <Grid container spacing={0}>
          <Grid
            item
            xs={0.5}
            sx={{
              p: "5px",
              background: item?.AssetStatusColour ?? "#c3c3c3",
              borderRadius: "5px 0 0 5px",
            }}
          ></Grid>
          <Grid item xs={6.5} sx={{ p: "5px" }}>

            {item?.UnitNumber &&
              <Tooltip title={item?.UnitNumber} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Text Bold_text oneLineCSS">
                  {item?.UnitNumber || ""}
                </Box>
              </Tooltip>
            }

            {item?.MakeName &&
              <Tooltip title={item?.MakeName} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Text oneLineCSS">
                  Make Name : {item?.MakeName || "-"}
                </Box>
              </Tooltip>
            }

            {item?.Model &&
              <Tooltip title={item?.Model} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Text oneLineCSS">
                  Model : {item?.Model || "-"}
                </Box>
              </Tooltip>
            }

            {item?.SerialNumber &&
              <Tooltip title={item?.SerialNumber} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Text oneLineCSS">
                  Serial Number : {item?.SerialNumber || "-"}
                </Box>
              </Tooltip>
            }

            {(item?.LastKnownSMU !== null && item?.LastKnownSMU !== undefined) &&
              <Tooltip title={item?.LastKnownSMU} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Text oneLineCSS">
                  Current SMU : {item?.LastKnownSMU}
                </Box>
              </Tooltip>
            }

            {item?.AssetDescription &&
              <Tooltip title={item?.AssetDescription} placement="top">
                <Box
                  sx={{ whiteSpace: "pre-wrap" }}
                  className="Dashboard_Asset_Card_Text oneLineCSS"
                >
                  Asset Decription : {item?.AssetDescription || "-"}
                </Box>
              </Tooltip>
            }

            {(item?.Address1 || item?.Address2 || item?.City || item?.Province || item?.Country || item?.PostalCode) &&
              <Tooltip title={location || "-"} placement="top">
                <Box sx={{ whiteSpace: "normal" }} className="Dashboard_Asset_Card_Text oneLineCSS">
                  Location : {location || "-"}
                </Box>
              </Tooltip>
            }
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ background: "#f5f5f5", p: "5px", textAlign: "start" }}
            className="Dashboard_Asset_Card_Text_Highlight"
          >

            {item?.CurrentRentalContract &&
              <Tooltip title={item?.CurrentRentalContract} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Contract Number : {item?.CurrentRentalContract || "-"}
                </Box>
              </Tooltip>
            }

            {(item?.CompanyName || item?.CurrentRentalCustomerName) &&
              <Tooltip title={item?.CurrentRentalContractId && item?.CurrentRentalContractId !== "" ? item?.CurrentRentalCustomerName : item?.CompanyName} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Company Name : {item?.CurrentRentalContractId && item?.CurrentRentalContractId !== "" ? item?.CurrentRentalCustomerName : item?.CompanyName}
                </Box>
              </Tooltip>
            }
            {item?.ContractStartDate &&
              <Tooltip title={item?.ContractStartDate?.split("T")?.[0]} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  StartDate : {item?.ContractStartDate?.split("T")?.[0] || "-"}
                </Box>
              </Tooltip>
            }

            {item?.ExpectedRentalReturnDate &&
              <Tooltip title={item?.ExpectedRentalReturnDate?.split(
                "T"
              )?.[0]} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Expected Return Date: {item?.ExpectedRentalReturnDate?.split(
                    "T"
                  )?.[0] || "-"}
                </Box>
              </Tooltip>
            }

            {(item?.LocationAddress1 || item?.LocationAddress2 || item?.LocationCity || item?.LocationPostalCode) &&
              <Tooltip title={CompanyLocation} placement="top">
                <Box sx={{ whiteSpace: "normal" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Location : {CompanyLocation || "-"}
                </Box>
              </Tooltip>
            }

            {(item?.ContractFirstName || item?.ContractLastName) &&
              <Tooltip title={`${item?.ContractFirstName || ""} ${item?.ContractLastName || ""}`} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Contact : {item?.ContractFirstName || ""} {item?.ContractLastName || ""} {!item?.ContractFirstName && !item?.ContractLastName ? "-" : ""}
                </Box>
              </Tooltip>
            }

            {item?.SalesRep &&
              <Tooltip title={item?.SalesRep} placement="top">
                <Box sx={{ whiteSpace: "pre-wrap" }} className="Dashboard_Asset_Card_Test_Color oneLineCSS">
                  Sales Rep : {item?.SalesRep || "-"}
                </Box>
              </Tooltip>
            }
          </Grid>
        </Grid>
      </Box>
    );
  };

  const GetAssetStatuses = async (e) => {
    setLoader({ ...loader, AssetStatusesLoader: true });
    dashboardService
      .GetAssetStatusesApi()
      .then((response) => {
        if (response.statusCode === 200) {
          setLoader({ ...loader, AssetStatusesLoader: false });
          let AssetStatuses = [];
          console.log("response?.data", response?.data);

          const responseData = response?.data?.filter(
            (item) => item?.IsVisible
          );
          responseData?.map((item, index) => {
            let newAssets = item;

            let assetsShow = DisplayStatuesLocalStorage?.filter(
              (DisplayStatuesItem) =>
                DisplayStatuesItem?.AssetStatusId === item?.AssetStatusId
            );
            newAssets = {
              ...newAssets,
              navigationColor: navigationColorColor?.[index]?.color,
              show: assetsShow?.[0]?.show ?? false,
            };
            AssetStatuses.push(newAssets);
          });
          setAssetStatuses(AssetStatuses);
          setAssetDisplayStatues(AssetStatuses);
        } else {
          setLoader({ ...loader, AssetStatusesLoader: false });
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));

    setLoader({ ...loader, AssetStatusesLoader: false });
  };

  const GetTierWiseAssetCategorizationSummaryDMS = async () => {
    setAssetCategorizationSummaryDMSLoader(true);
    dashboardService
      .GetTierWiseAssetCategorizationSummaryDMSAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setAssetCategorizationSummaryDMSLoader(false);
          setAssetTypeList(response.data);
        } else {
          setAssetCategorizationSummaryDMSLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  // ---------------------------------------------

  const handelSelectAssetTypeList = async (item, Type) => {
    resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        ShowBackBTN: true,
      });
    } else if (Type?.Type === "CategoryDetails") {
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };

  const handelShowOnlyOneCategory = (item, Type) => {
    if (Type?.Type === "AssetList") {
      setShowParticularCategory({
        categoryID: item?.AssetTypeId,
        categoryName: item?.AssetTypeName,
        showOnlyOneCategory: true,
      });
    } else if (Type?.Type === "CategoryDetails") {
      setShowParticularCategory({
        categoryID: item?.CategoryId,
        categoryName: item?.CategoryName,
        showOnlyOneCategory: true,
      });
    } else if (Type?.Type === "TierData") {
      setShowParticularCategory({
        categoryID: item?.Tier1Id,
        categoryName: item?.Tier1Name,
        showOnlyOneCategory: true,
      });
    }
  };

  const handelSelectAssetTypeListBackFun = () => {
    if (
      assetList?.selectAssetDetails &&
      Object.keys(assetList?.selectAssetDetails).length !== 0 &&
      assetList?.tierCount === 0
    ) {
      setAssetList({
        ...assetList,

        ShowTitle: "",
        selectAssetDetails: {},
        ShowBackBTN: false,
      });
    } else if (assetList?.tierCount !== 0) {
      switch (assetList?.tierCount) {
        case 0:
          return setAssetList({
            ...assetList,
            ShowTitle: "",
            CategoryDetails: {},
            Tier1Select: {},
            Tier1Details: [],
            TierData: false,
          });
        case 1:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.selectAssetDetails?.AssetTypeName,
            Tier1Select: {},
            CategoryDetails: {},
            Tier2Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: false,
          });
        case 2:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.CategoryDetails?.CategoryName,
            Tier2Select: {},
            Tier1Select: {},
            Tier3Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 3:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier1Select?.Tier1Name,
            Tier3Select: {},
            Tier2Select: {},
            Tier4Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 4:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier2Select?.Tier2Name,
            Tier4Select: {},
            Tier3Select: {},
            Tier5Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });

        case 5:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier3Select?.Tier3Name,
            Tier4Select: {},
            Tier6Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        default:
          return;
      }
    }
  };

  const GetStores = () => {
    dashboardService
      .GetStoresAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setStoreList(response.data);
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const GetPagedAssets = (newPageDetails) => {
    setDashboardLoader(true);
    handleStop();
    let AssetStatuses = [];
    let AssetsPagination = [];
    let maxLength = 0;
    let recodeCount = {};
    if (!loader?.AssetStatusesLoader) {
      const showStatus = assetStatuses
        .filter((item) => item.show)
        .map((item) => item.AssetStatusId);
      if (
        assetList?.selectAssetDetails &&
        Object.keys(assetList?.selectAssetDetails).length === 0
      ) {
        showParticularCategory?.showOnlyOneCategory
          ? AssetTypeList?.filter(
            (item) => item?.AssetTypeId === showParticularCategory?.categoryID
          )?.map((item, index) => {
            setLoader({ ...loader, [item.AssetTypeName]: true });
            let payLoead = {
              Filter: {
                All: null,
                Company: null,
                SerialNumber: null,
                Model: null,
                Year: null,
                UnitNumber: null,
                Location: null,
                statusIds: showStatus,
                Category: [],
                TargetSellDate: null,
                LastSMUDate: null,
                CompanyIds: settingValue,
                TargetSaleDateIsNull: null,
                ParentAssetIdIsNULL: null,
                IndAvailableAssets: false,
                MinYear: null,
                MaxYear: null,
                MinSMU: null,
                MaxSMU: null,
                Make: null,
                StatusId: null,
                OnlyInventoryFlag: null,
                MakeName: null,
                AssetIds: null,
                StoreIds: [],
              },
              PageSize: showAssetCount,
              Sort: "ChangeDate_desc",
              IncludeCount: true,
            };
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                AssetTypeId: [item?.AssetTypeId],
              },
            };

            if (newPageDetails !== undefined) {
              payLoead = {
                ...payLoead,
                PageNumber:
                  newPageDetails?.[item.AssetTypeName]?.PageDetails
                    ?.PageNumber,
              };
            } else {
              payLoead = {
                ...payLoead,
                PageNumber: 1,
              };
            }

            if (storeCodeSelect) {
              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  StoreIds: [storeCodeSelect],
                },
              };
            } else {
              payLoead = {
                ...payLoead,
                Filter: { ...payLoead?.Filter, StoreIds: [] },
              };
            }

            let loaders = loader;
            loaders = { ...loaders, [item.AssetTypeName]: true };
            setLoader(loaders);
            dashboardService
              .GetPagedAssetsRentalRAPI(payLoead)
              .then((response) => {
                if (response.statusCode === 200) {
                  loaders = { ...loaders, [item.AssetTypeName]: false };
                  setLoader(loaders);
                  if (maxLength < response?.length?.TotalPages) {
                    maxLength = response?.length?.TotalPages;
                  }
                  setTotalPage(maxLength);
                  recodeCount = {
                    ...recodeCount,
                    [item.AssetTypeName]: {
                      totalAssetsRecode: response?.length?.TotalRecords,
                    },
                  };
                  AssetStatuses = {
                    ...AssetStatuses,
                    [item.AssetTypeName]: {
                      data: response?.data,
                      totalCount: response?.data?.length,
                      start: 0,
                      end: showAssetCount,
                    },
                  };

                  AssetsPagination = {
                    ...AssetsPagination,

                    [item.AssetTypeName]: {
                      PageDetails: response?.length,
                    },
                  };
                  setAssetStatusesListPagination(AssetsPagination);
                  setAssetStatusesDetails(AssetStatuses);
                  setTotalAssetsRecode(recodeCount);
                  if (AssetTypeList?.length === index + 1) {
                    setDashboardLoader(false);
                    handleStart();
                  }
                } else {
                  setLoader({ ...loader, [item.AssetTypeName]: false });
                  if (response.statusCode === 401) {
                    logOut();
                  }
                }
              })
              .catch((error) => console.log("Login Api Error =====", error));
            setLoader({ ...loader, [item.AssetTypeName]: false });
          })
          : AssetTypeList?.map((item, index) => {
            setLoader({ ...loader, [item.AssetTypeName]: true });
            let payLoead = {
              Filter: {
                All: null,
                Company: null,
                SerialNumber: null,
                Model: null,
                Year: null,
                UnitNumber: null,
                Location: null,
                statusIds: showStatus,
                Category: [],
                TargetSellDate: null,
                LastSMUDate: null,
                CompanyIds: settingValue,
                TargetSaleDateIsNull: null,
                ParentAssetIdIsNULL: null,
                IndAvailableAssets: false,
                MinYear: null,
                MaxYear: null,
                MinSMU: null,
                MaxSMU: null,
                Make: null,
                StatusId: null,
                OnlyInventoryFlag: null,
                MakeName: null,
                AssetIds: null,
                StoreIds: [],
              },
              PageSize: showAssetCount,
              Sort: "ChangeDate_desc",
              IncludeCount: true,
            };
            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                AssetTypeId: [item?.AssetTypeId],
              },
            };

            if (newPageDetails !== undefined) {
              payLoead = {
                ...payLoead,
                PageNumber:
                  newPageDetails?.[item.AssetTypeName]?.PageDetails
                    ?.PageNumber,
              };
            } else {
              payLoead = {
                ...payLoead,
                PageNumber: 1,
              };
            }

            if (storeCodeSelect) {
              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  StoreIds: [storeCodeSelect],
                },
              };
            } else {
              payLoead = {
                ...payLoead,
                Filter: { ...payLoead?.Filter, StoreIds: [] },
              };
            }

            let loaders = loader;
            loaders = { ...loaders, [item.AssetTypeName]: true };
            setLoader(loaders);
            dashboardService
              .GetPagedAssetsAPI(payLoead)
              .then((response) => {
                if (response.statusCode === 200) {
                  loaders = { ...loaders, [item.AssetTypeName]: false };
                  setLoader(loaders);
                  if (maxLength < response?.length?.TotalPages) {
                    maxLength = response?.length?.TotalPages;
                  }
                  setTotalPage(maxLength);
                  recodeCount = {
                    ...recodeCount,
                    [item.AssetTypeName]: {
                      totalAssetsRecode: response?.length?.TotalRecords,
                    },
                  };
                  AssetStatuses = {
                    ...AssetStatuses,
                    [item.AssetTypeName]: {
                      data: response?.data,
                      totalCount: response?.data?.length,
                      start: 0,
                      end: showAssetCount,
                    },
                  };

                  AssetsPagination = {
                    ...AssetsPagination,

                    [item.AssetTypeName]: {
                      PageDetails: response?.length,
                    },
                  };
                  setAssetStatusesListPagination(AssetsPagination);
                  setAssetStatusesDetails(AssetStatuses);
                  setTotalAssetsRecode(recodeCount);
                  if (AssetTypeList?.length === index + 1) {
                    setDashboardLoader(false);
                    handleStart();
                  }
                } else {
                  setLoader({ ...loader, [item.AssetTypeName]: false });
                  if (response.statusCode === 401) {
                    logOut();
                  }
                }
              })
              .catch((error) => console.log("Login Api Error =====", error));
            setLoader({ ...loader, [item.AssetTypeName]: false });
          });
      } else if (!assetList?.TierData) {
        showParticularCategory?.showOnlyOneCategory
          ? assetList?.selectAssetDetails?.CategoryDetails?.filter(
            (item) => item?.CategoryId === showParticularCategory?.categoryID
          )?.map((CategoryItem, index) => {
            setLoader({ ...loader, [CategoryItem?.CategoryName]: true });
            let payLoead = {
              Filter: {
                All: null,
                Company: null,
                SerialNumber: null,
                Model: null,
                Year: null,
                UnitNumber: null,
                Location: null,
                statusIds: showStatus,
                Category: [],
                TargetSellDate: null,
                LastSMUDate: null,
                CompanyIds: settingValue,
                TargetSaleDateIsNull: null,
                ParentAssetIdIsNULL: null,
                IndAvailableAssets: false,
                MinYear: null,
                MaxYear: null,
                MinSMU: null,
                MaxSMU: null,
                Make: null,
                StatusId: null,
                OnlyInventoryFlag: null,
                MakeName: null,
                AssetIds: null,
                StoreIds: [],
              },
              PageSize: showAssetCount,
              Sort: "ChangeDate_desc",
              IncludeCount: true,
            };

            if (newPageDetails !== undefined) {
              payLoead = {
                ...payLoead,
                PageNumber:
                  newPageDetails?.[CategoryItem?.CategoryName]?.PageDetails
                    ?.PageNumber,
              };
            } else {
              payLoead = {
                ...payLoead,
                PageNumber: 1,
              };
            }
            if (storeCodeSelect) {
              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  StoreIds: [storeCodeSelect],
                },
              };
            } else {
              payLoead = {
                ...payLoead,
                Filter: { ...payLoead?.Filter, StoreIds: [] },
              };
            }

            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                AssetTypeId: [assetList?.selectAssetDetails?.AssetTypeId],
              },
            };

            payLoead = {
              ...payLoead,
              Filter: {
                ...payLoead?.Filter,
                CategoryName: [CategoryItem?.CategoryName],
              },
            };

            let loaders = loader;
            loaders = { ...loaders, [CategoryItem?.CategoryName]: true };
            setLoader(loaders);
            dashboardService
              .GetPagedAssetsRentalRAPI(payLoead)
              .then((response) => {
                if (response.statusCode === 200) {
                  loaders = {
                    ...loaders,
                    [CategoryItem?.CategoryName]: false,
                  };
                  setLoader(loaders);
                  if (maxLength < response?.length?.TotalPages) {
                    maxLength = response?.length?.TotalPages;
                  }
                  setTotalPage(maxLength);
                  recodeCount = {
                    ...recodeCount,
                    [CategoryItem?.CategoryName]: {
                      totalAssetsRecode: response?.length?.TotalRecords,
                    },
                  };
                  AssetStatuses = {
                    ...AssetStatuses,
                    [CategoryItem?.CategoryName]: {
                      data: response?.data,
                      totalCount: response?.data?.length,
                      start: 0,
                      end: showAssetCount,
                    },
                  };

                  AssetsPagination = {
                    ...AssetsPagination,

                    [CategoryItem?.CategoryName]: {
                      PageDetails: response?.length,
                    },
                  };
                  setAssetStatusesListPagination(AssetsPagination);
                  setAssetStatusesDetails(AssetStatuses);
                  setTotalAssetsRecode(recodeCount);

                  if (
                    assetList?.selectAssetDetails?.CategoryDetails?.filter(
                      (item) =>
                        item?.CategoryId ===
                        showParticularCategory?.categoryID
                    )?.length ===
                    index + 1
                  ) {
                    setDashboardLoader(false);
                    handleStart();
                  }
                } else {
                  setLoader({
                    ...loader,
                    [CategoryItem?.CategoryName]: false,
                  });
                  if (response.statusCode === 401) {
                    logOut();
                  }
                }
              })
              .catch((error) => console.log("Login Api Error =====", error));
            setLoader({ ...loader, [CategoryItem?.CategoryName]: false });
          })
          : assetList?.selectAssetDetails?.CategoryDetails?.map(
            (CategoryItem, index) => {
              setLoader({ ...loader, [CategoryItem?.CategoryName]: true });
              let payLoead = {
                Filter: {
                  All: null,
                  Company: null,
                  SerialNumber: null,
                  Model: null,
                  Year: null,
                  UnitNumber: null,
                  Location: null,
                  statusIds: showStatus,
                  Category: [],
                  TargetSellDate: null,
                  LastSMUDate: null,
                  CompanyIds: settingValue,
                  TargetSaleDateIsNull: null,
                  ParentAssetIdIsNULL: null,
                  IndAvailableAssets: false,
                  MinYear: null,
                  MaxYear: null,
                  MinSMU: null,
                  MaxSMU: null,
                  Make: null,
                  StatusId: null,
                  OnlyInventoryFlag: null,
                  MakeName: null,
                  AssetIds: null,
                  StoreIds: [],
                },
                PageSize: showAssetCount,
                Sort: "ChangeDate_desc",
                IncludeCount: true,
              };

              if (newPageDetails !== undefined) {
                payLoead = {
                  ...payLoead,
                  PageNumber:
                    newPageDetails?.[CategoryItem?.CategoryName]?.PageDetails
                      ?.PageNumber,
                };
              } else {
                payLoead = {
                  ...payLoead,
                  PageNumber: 1,
                };
              }
              if (storeCodeSelect) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    StoreIds: [storeCodeSelect],
                  },
                };
              } else {
                payLoead = {
                  ...payLoead,
                  Filter: { ...payLoead?.Filter, StoreIds: [] },
                };
              }

              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  AssetTypeId: [assetList?.selectAssetDetails?.AssetTypeId],
                },
              };

              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  CategoryName: [CategoryItem?.CategoryName],
                },
              };

              let loaders = loader;
              loaders = { ...loaders, [CategoryItem?.CategoryName]: true };
              setLoader(loaders);
              dashboardService
                .GetPagedAssetsAPI(payLoead)
                .then((response) => {
                  if (response.statusCode === 200) {
                    loaders = {
                      ...loaders,
                      [CategoryItem?.CategoryName]: false,
                    };
                    setLoader(loaders);
                    if (maxLength < response?.length?.TotalPages) {
                      maxLength = response?.length?.TotalPages;
                    }
                    setTotalPage(maxLength);
                    recodeCount = {
                      ...recodeCount,
                      [CategoryItem?.CategoryName]: {
                        totalAssetsRecode: response?.length?.TotalRecords,
                      },
                    };
                    AssetStatuses = {
                      ...AssetStatuses,
                      [CategoryItem?.CategoryName]: {
                        data: response?.data,
                        totalCount: response?.data?.length,
                        start: 0,
                        end: showAssetCount,
                      },
                    };

                    AssetsPagination = {
                      ...AssetsPagination,

                      [CategoryItem?.CategoryName]: {
                        PageDetails: response?.length,
                      },
                    };
                    setAssetStatusesListPagination(AssetsPagination);
                    setAssetStatusesDetails(AssetStatuses);
                    setTotalAssetsRecode(recodeCount);

                    if (
                      assetList?.selectAssetDetails?.CategoryDetails
                        ?.length ===
                      index + 1
                    ) {
                      setDashboardLoader(false);
                      handleStart();
                    }
                  } else {
                    setLoader({
                      ...loader,
                      [CategoryItem?.CategoryName]: false,
                    });
                    if (response.statusCode === 401) {
                      logOut();
                    }
                  }
                })
                .catch((error) =>
                  console.log("Login Api Error =====", error)
                );
              setLoader({ ...loader, [CategoryItem?.CategoryName]: false });
            }
          );
      } else {
        showParticularCategory?.showOnlyOneCategory
          ? assetList?.[`Tier${assetList?.tierCount}Details`]
            ?.filter(
              (item) => item?.Tier1Id === showParticularCategory?.categoryID
            )
            ?.map((TierItem, index) => {
              setLoader({
                ...loader,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: true,
              });
              let payLoead = {
                Filter: {
                  All: null,
                  Company: null,
                  SerialNumber: null,
                  Model: null,
                  Year: null,
                  UnitNumber: null,
                  Location: null,
                  statusIds: showStatus,
                  Category: [],
                  TargetSellDate: null,
                  LastSMUDate: null,
                  CompanyIds: settingValue,
                  TargetSaleDateIsNull: null,
                  ParentAssetIdIsNULL: null,
                  IndAvailableAssets: false,
                  MinYear: null,
                  MaxYear: null,
                  MinSMU: null,
                  MaxSMU: null,
                  Make: null,
                  StatusId: null,
                  OnlyInventoryFlag: null,
                  MakeName: null,
                  AssetIds: null,
                  StoreIds: [],
                },
                PageSize: showAssetCount,
                Sort: "ChangeDate_desc",
                IncludeCount: true,
              };
              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  AssetTypeId: [assetList?.selectAssetDetails?.AssetTypeId],
                },
              };

              if (storeCodeSelect) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    StoreIds: [storeCodeSelect],
                  },
                };
              } else {
                payLoead = {
                  ...payLoead,
                  Filter: { ...payLoead?.Filter, StoreIds: [] },
                };
              }

              const tierName = TierItem?.[`Tier${assetList?.tierCount}Name`];
              if (newPageDetails !== undefined) {
                payLoead = {
                  ...payLoead,
                  PageNumber:
                    newPageDetails?.[tierName]?.PageDetails?.PageNumber,
                };
              } else {
                payLoead = {
                  ...payLoead,
                  PageNumber: 1,
                };
              }

              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  CategoryName: [assetList?.CategoryDetails?.CategoryName],
                },
              };

              if (assetList?.tierCount === 1) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [
                      TierItem?.[`Tier${assetList?.tierCount}Name`],
                    ],
                  },
                };
              }

              if (assetList?.tierCount === 2) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [TierItem?.Tier2Name],
                  },
                };
              }

              if (assetList?.tierCount === 3) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [assetList?.Tier2Select?.Tier2Name],
                    Tier3Name: [TierItem?.Tier3Name],
                  },
                };
              }

              if (assetList?.tierCount === 4) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [assetList?.Tier2Select?.Tier2Name],
                    Tier3Name: [assetList?.Tier3Select?.Tier3Name],
                    Tier4Name: [TierItem?.Tier4Name],
                  },
                };
              }

              let loaders = loader;
              loaders = {
                ...loaders,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: true,
              };
              setLoader(loaders);
              dashboardService
                .GetPagedAssetsRentalRAPI(payLoead)
                .then((response) => {
                  if (response.statusCode === 200) {
                    loaders = {
                      ...loaders,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
                    };
                    setLoader(loaders);
                    if (maxLength < response?.length?.TotalPages) {
                      maxLength = response?.length?.TotalPages;
                    }
                    setTotalPage(maxLength);
                    recodeCount = {
                      ...recodeCount,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        totalAssetsRecode: response?.length?.TotalRecords,
                      },
                    };
                    AssetStatuses = {
                      ...AssetStatuses,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        data: response?.data,
                        totalCount: response?.data?.length,
                        start: 0,
                        end: showAssetCount,
                      },
                    };

                    AssetsPagination = {
                      ...AssetsPagination,

                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        PageDetails: response?.length,
                      },
                    };
                    setAssetStatusesListPagination(AssetsPagination);
                    setAssetStatusesDetails(AssetStatuses);
                    setTotalAssetsRecode(recodeCount);

                    if (
                      assetList?.[
                        `Tier${assetList?.tierCount}Details`
                      ]?.filter(
                        (item) =>
                          item?.Tier1Id === showParticularCategory?.categoryID
                      )?.length ===
                      index + 1
                    ) {
                      setDashboardLoader(false);
                      handleStart();
                    }
                  } else {
                    setLoader({
                      ...loader,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
                    });
                    if (response.statusCode === 401) {
                      logOut();
                    }
                  }
                })
                .catch((error) =>
                  console.log("Login Api Error =====", error)
                );
              setLoader({
                ...loader,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
              });
            })
          : assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
            (TierItem, index) => {
              setLoader({
                ...loader,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: true,
              });
              let payLoead = {
                Filter: {
                  All: null,
                  Company: null,
                  SerialNumber: null,
                  Model: null,
                  Year: null,
                  UnitNumber: null,
                  Location: null,
                  statusIds: showStatus,
                  Category: [],
                  TargetSellDate: null,
                  LastSMUDate: null,
                  CompanyIds: settingValue,
                  TargetSaleDateIsNull: null,
                  ParentAssetIdIsNULL: null,
                  IndAvailableAssets: false,
                  MinYear: null,
                  MaxYear: null,
                  MinSMU: null,
                  MaxSMU: null,
                  Make: null,
                  StatusId: null,
                  OnlyInventoryFlag: null,
                  MakeName: null,
                  AssetIds: null,
                  StoreIds: [],
                },
                PageSize: showAssetCount,
                Sort: "ChangeDate_desc",
                IncludeCount: true,
              };
              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  AssetTypeId: [assetList?.selectAssetDetails?.AssetTypeId],
                },
              };

              if (storeCodeSelect) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    StoreIds: [storeCodeSelect],
                  },
                };
              } else {
                payLoead = {
                  ...payLoead,
                  Filter: { ...payLoead?.Filter, StoreIds: [] },
                };
              }

              const tierName = TierItem?.[`Tier${assetList?.tierCount}Name`];
              if (newPageDetails !== undefined) {
                payLoead = {
                  ...payLoead,
                  PageNumber:
                    newPageDetails?.[tierName]?.PageDetails?.PageNumber,
                };
              } else {
                payLoead = {
                  ...payLoead,
                  PageNumber: 1,
                };
              }

              payLoead = {
                ...payLoead,
                Filter: {
                  ...payLoead?.Filter,
                  CategoryName: [assetList?.CategoryDetails?.CategoryName],
                },
              };

              if (assetList?.tierCount === 1) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [
                      TierItem?.[`Tier${assetList?.tierCount}Name`],
                    ],
                  },
                };
              }

              if (assetList?.tierCount === 2) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [TierItem?.Tier2Name],
                  },
                };
              }

              if (assetList?.tierCount === 3) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [assetList?.Tier2Select?.Tier2Name],
                    Tier3Name: [TierItem?.Tier3Name],
                  },
                };
              }

              if (assetList?.tierCount === 4) {
                payLoead = {
                  ...payLoead,
                  Filter: {
                    ...payLoead?.Filter,
                    Tier1Name: [assetList?.Tier1Select?.Tier1Name],
                    Tier2Name: [assetList?.Tier2Select?.Tier2Name],
                    Tier3Name: [assetList?.Tier3Select?.Tier3Name],
                    Tier4Name: [TierItem?.Tier4Name],
                  },
                };
              }

              let loaders = loader;
              loaders = {
                ...loaders,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: true,
              };
              setLoader(loaders);
              dashboardService
                .GetPagedAssetsAPI(payLoead)
                .then((response) => {
                  if (response.statusCode === 200) {
                    loaders = {
                      ...loaders,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
                    };
                    setLoader(loaders);
                    if (maxLength < response?.length?.TotalPages) {
                      maxLength = response?.length?.TotalPages;
                    }
                    setTotalPage(maxLength);
                    recodeCount = {
                      ...recodeCount,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        totalAssetsRecode: response?.length?.TotalRecords,
                      },
                    };
                    AssetStatuses = {
                      ...AssetStatuses,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        data: response?.data,
                        totalCount: response?.data?.length,
                        start: 0,
                        end: showAssetCount,
                      },
                    };

                    AssetsPagination = {
                      ...AssetsPagination,

                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                        PageDetails: response?.length,
                      },
                    };
                    setAssetStatusesListPagination(AssetsPagination);
                    setAssetStatusesDetails(AssetStatuses);
                    setTotalAssetsRecode(recodeCount);

                    if (
                      assetList?.[`Tier${assetList?.tierCount}Details`]
                        ?.length ===
                      index + 1
                    ) {
                      setDashboardLoader(false);
                      handleStart();
                    }
                  } else {
                    setLoader({
                      ...loader,
                      [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
                    });
                    if (response.statusCode === 401) {
                      logOut();
                    }
                  }
                })
                .catch((error) =>
                  console.log("Login Api Error =====", error)
                );
              setLoader({
                ...loader,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: false,
              });
            }
          );
      }
    }
  };
  // ----------------------------------------------

  const logOut = () => {
    navigate("/");
    localStorage.removeItem("ACCESS_TOKENS");
    localStorage.removeItem("StoreCode");
    localStorage.removeItem("DisplayStatues-V2");
    localStorage.removeItem("rowPerPage");
    localStorage.removeItem("rowPerPageInDR");
    localStorage.removeItem("DisplayStatuesInDR");
    localStorage.removeItem("StoreCodeInDR");
    localStorage.removeItem("SELECT_BOARD");
  };

  const Delivery_Receipt_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "delivery-receipt");
    navigate("/delivery-receipt");
  };

  const Dashboard_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "dashboard");
    navigate("/dashboard");
  };

  React.useEffect(() => {
    GetAssetStatuses();
    GetTierWiseAssetCategorizationSummaryDMS();
  }, []);

  const handelResetPagination = async () => {
    if (!dashboardLoader) {
      let newPageDetails = [];
      if (
        assetList?.selectAssetDetails &&
        Object.keys(assetList?.selectAssetDetails).length === 0
      ) {
        showParticularCategory?.showOnlyOneCategory
          ? AssetTypeList?.filter(
            (item) => item?.AssetTypeId === showParticularCategory?.categoryID
          )?.map((item, index) => {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]: {
                ...assetStatusesListPagination?.[item.AssetTypeName],
                PageDetails: {
                  ...assetStatusesListPagination?.[item.AssetTypeName]
                    ?.PageDetails,
                  PageNumber: 1,
                },
              },
            };
          })
          : AssetTypeList?.map((item, index) => {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]: {
                ...assetStatusesListPagination?.[item.AssetTypeName],
                PageDetails: {
                  ...assetStatusesListPagination?.[item.AssetTypeName]
                    ?.PageDetails,
                  PageNumber: 1,
                },
              },
            };
          });
      } else if (!assetList?.TierData) {
        showParticularCategory?.showOnlyOneCategory
          ? assetList?.selectAssetDetails?.CategoryDetails?.filter(
            (item) => item?.CategoryId === showParticularCategory?.categoryID
          )?.map((CategoryItem, index) => {
            newPageDetails = {
              ...newPageDetails,
              [CategoryItem?.CategoryName]: {
                ...assetStatusesListPagination?.[CategoryItem?.CategoryName],
                PageDetails: {
                  ...assetStatusesListPagination?.[CategoryItem?.CategoryName]
                    ?.PageDetails,
                  PageNumber: 1,
                },
              },
            };
          })
          : assetList?.selectAssetDetails?.CategoryDetails?.map(
            (CategoryItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [CategoryItem?.CategoryName]: {
                  ...assetStatusesListPagination?.[
                  CategoryItem?.CategoryName
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      CategoryItem?.CategoryName
                    ]?.PageDetails,
                    PageNumber: 1,
                  },
                },
              };
            }
          );
      } else {
        showParticularCategory?.showOnlyOneCategory
          ? assetList?.[`Tier${assetList?.tierCount}Details`]
            ?.filter(
              (item) => item?.Tier1Id === showParticularCategory?.categoryID
            )
            ?.map((TierItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                  ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      TierItem?.[`Tier${assetList?.tierCount}Name`]
                    ]?.PageDetails,
                    PageNumber: 1,
                  },
                },
              };
            })
          : assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
            (TierItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                  ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      TierItem?.[`Tier${assetList?.tierCount}Name`]
                    ]?.PageDetails,
                    PageNumber: 1,
                  },
                },
              };
            }
          );
      }

      setCurrentPage(1);
      setAssetStatusesListPagination(newPageDetails);
      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
      setTimeAutoNextPage(!timeAutoNextPage);
    }
  };

  React.useEffect(() => {
    handelResetPagination();
  }, [showParticularCategory?.showOnlyOneCategory]);

  React.useEffect(() => {
    setDashboardLoader(true);
    if (!settingValueLoader) {
      GetPagedAssets();
    }
  }, [
    AssetTypeList,
    storeCodeSelect,
    showAssetCount,
    settingValueLoader,
    loader?.AssetStatusesLoader,
    assetStatuses,
    assetList?.selectAssetDetails?.AssetTypeId,
    assetList?.CategoryDetails?.CategoryName,
    assetList?.Tier1Select?.Tier1Name,
    assetList?.Tier2Select?.Tier2Name,
    assetList?.Tier3Select?.Tier3Name,
    assetList?.Tier4Select?.Tier4Name,
  ]);

  React.useEffect(() => {
    GetStores();
  }, []);

  const handelpagination = () => {
    if (!dashboardLoader) {
      let newPageDetails = [];
      if (totalPage > currentPage) {
        if (
          assetList?.selectAssetDetails &&
          Object.keys(assetList?.selectAssetDetails).length === 0
        ) {
          AssetTypeList?.map((item, index) => {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]: {
                ...assetStatusesListPagination?.[item.AssetTypeName],
                PageDetails: {
                  ...assetStatusesListPagination?.[item.AssetTypeName]
                    ?.PageDetails,
                  PageNumber:
                    assetStatusesListPagination?.[item.AssetTypeName]
                      ?.PageDetails?.PageNumber + 1,
                },
              },
            };
          });
        } else if (!assetList?.TierData) {
          assetList?.selectAssetDetails?.CategoryDetails?.map(
            (CategoryItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [CategoryItem?.CategoryName]: {
                  ...assetStatusesListPagination?.[CategoryItem?.CategoryName],
                  PageDetails: {
                    ...assetStatusesListPagination?.[CategoryItem?.CategoryName]
                      ?.PageDetails,
                    PageNumber:
                      assetStatusesListPagination?.[CategoryItem?.CategoryName]
                        ?.PageDetails?.PageNumber + 1,
                  },
                },
              };
            }
          );
        } else {
          assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
            (TierItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                  ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      TierItem?.[`Tier${assetList?.tierCount}Name`]
                    ]?.PageDetails,
                    PageNumber:
                      assetStatusesListPagination?.[
                        TierItem?.[`Tier${assetList?.tierCount}Name`]
                      ]?.PageDetails?.PageNumber + 1,
                  },
                },
              };
            }
          );
        }
        setCurrentPage(currentPage + 1);
      } else {
        if (
          assetList?.selectAssetDetails &&
          Object.keys(assetList?.selectAssetDetails).length === 0
        ) {
          AssetTypeList?.map((item, index) => {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]: {
                ...assetStatusesListPagination?.[item.AssetTypeName],
                PageDetails: {
                  ...assetStatusesListPagination?.[item.AssetTypeName]
                    ?.PageDetails,
                  PageNumber: 1,
                },
              },
            };
          });
        } else if (!assetList?.TierData) {
          assetList?.selectAssetDetails?.CategoryDetails?.map(
            (CategoryItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [CategoryItem?.CategoryName]: {
                  ...assetStatusesListPagination?.[CategoryItem?.CategoryName],
                  PageDetails: {
                    ...assetStatusesListPagination?.[CategoryItem?.CategoryName]
                      ?.PageDetails,
                    PageNumber: 1,
                  },
                },
              };
            }
          );
        } else {
          assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
            (TierItem, index) => {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                  ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      TierItem?.[`Tier${assetList?.tierCount}Name`]
                    ]?.PageDetails,
                    PageNumber: 1,
                  },
                },
              };
            }
          );
        }
        setCurrentPage(1);
      }
      setAssetStatusesListPagination(newPageDetails);
      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
      setTimeAutoNextPage(!timeAutoNextPage);
    }
  };

  const handelpaginationBack = () => {
    let newPageDetails = [];
    if (currentPage > 1) {
      if (
        assetList?.selectAssetDetails &&
        Object.keys(assetList?.selectAssetDetails).length === 0
      ) {
        AssetTypeList?.map((item, index) => {
          if (
            assetStatusesListPagination?.[item.AssetTypeName]?.PageDetails
              ?.PageNumber -
            1 >
            0
          ) {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]: {
                ...assetStatusesListPagination?.[item.AssetTypeName],
                PageDetails: {
                  ...assetStatusesListPagination?.[item.AssetTypeName]
                    ?.PageDetails,
                  PageNumber:
                    assetStatusesListPagination?.[item.AssetTypeName]
                      ?.PageDetails?.PageNumber - 1,
                },
              },
            };
          } else {
            newPageDetails = {
              ...newPageDetails,
              [item.AssetTypeName]:
                assetStatusesListPagination?.[item.AssetTypeName],
            };
          }
        });
      } else if (!assetList?.TierData) {
        assetList?.selectAssetDetails?.CategoryDetails?.map(
          (CategoryItem, index) => {
            if (
              assetStatusesListPagination?.[CategoryItem?.CategoryName]
                ?.PageDetails?.PageNumber -
              1 >
              0
            ) {
              newPageDetails = {
                ...newPageDetails,
                [CategoryItem?.CategoryName]: {
                  ...assetStatusesListPagination?.[CategoryItem?.CategoryName],
                  PageDetails: {
                    ...assetStatusesListPagination?.[CategoryItem?.CategoryName]
                      ?.PageDetails,
                    PageNumber:
                      assetStatusesListPagination?.[CategoryItem?.CategoryName]
                        ?.PageDetails?.PageNumber - 1,
                  },
                },
              };
            } else {
              newPageDetails = {
                ...newPageDetails,
                [CategoryItem?.CategoryName]:
                  assetStatusesListPagination?.[CategoryItem?.CategoryName],
              };
            }
          }
        );
      } else {
        assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
          (TierItem, index) => {
            if (
              assetStatusesListPagination?.[
                TierItem?.[`Tier${assetList?.tierCount}Name`]
              ]?.PageDetails?.PageNumber -
              1 >
              0
            ) {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
                  ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
                  PageDetails: {
                    ...assetStatusesListPagination?.[
                      TierItem?.[`Tier${assetList?.tierCount}Name`]
                    ]?.PageDetails,
                    PageNumber:
                      assetStatusesListPagination?.[
                        TierItem?.[`Tier${assetList?.tierCount}Name`]
                      ]?.PageDetails?.PageNumber - 1,
                  },
                },
              };
            } else {
              newPageDetails = {
                ...newPageDetails,
                [TierItem?.[`Tier${assetList?.tierCount}Name`]]:
                  assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                  ],
              };
            }
          }
        );
      }
      setAssetStatusesListPagination(newPageDetails);
      setCurrentPage(currentPage - 1);

      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelSelectStatuesModel = () => {
    setAssetDisplayStatues(assetStatuses);
    setSelectStatuesModel(false);
  };

  const handelSelectStatuesInfoModel = () => {
    setShowDetails({
      info: null,
      model: false,
    });
    setContractInfo();
    setContractImgInfo();
    setContractInfoLoader(false);
  };

  const handelSaveSelectStatuesModel = async () => {
    await resetPagination();
    localStorage.setItem(
      "DisplayStatues-V2",
      JSON.stringify(assetDisplayStatues)
    );
    setDisplayStatuesLocalStorage(assetDisplayStatues);
    setAssetStatuses(assetDisplayStatues);
    setSelectStatuesModel(false);
  };

  const nextBtnRef = React.useRef();

  const nextBtnRefMap = React.useRef();

  const resetPagination = () => {
    let newPageDetails = [];
    if (
      assetList?.selectAssetDetails &&
      Object.keys(assetList?.selectAssetDetails).length === 0
    ) {
      AssetTypeList?.map((item, index) => {
        newPageDetails = {
          ...newPageDetails,
          [item.AssetTypeName]: {
            ...assetStatusesListPagination?.[item.AssetTypeName],
            PageDetails: {
              ...assetStatusesListPagination?.[item.AssetTypeName]?.PageDetails,
              PageNumber: 1,
            },
          },
        };
      });
    } else if (!assetList?.TierData) {
      assetList?.selectAssetDetails?.CategoryDetails?.map(
        (CategoryItem, index) => {
          newPageDetails = {
            ...newPageDetails,
            [CategoryItem?.CategoryName]: {
              ...assetStatusesListPagination?.[CategoryItem?.CategoryName],
              PageDetails: {
                ...assetStatusesListPagination?.[CategoryItem?.CategoryName]
                  ?.PageDetails,
                PageNumber: 1,
              },
            },
          };
        }
      );
    } else {
      assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
        (TierItem, index) => {
          newPageDetails = {
            ...newPageDetails,
            [TierItem?.[`Tier${assetList?.tierCount}Name`]]: {
              ...assetStatusesListPagination?.[
              TierItem?.[`Tier${assetList?.tierCount}Name`]
              ],
              PageDetails: {
                ...assetStatusesListPagination?.[
                  TierItem?.[`Tier${assetList?.tierCount}Name`]
                ]?.PageDetails,
                PageNumber: 1,
              },
            },
          };
        }
      );
    }
    setCurrentPage(1);
    setAssetStatusesListPagination(newPageDetails);
    return newPageDetails;
  };

  const handleStart = (autoPageNext) => {
    if (autoNextPage || autoPageNext) {
      const id = setInterval(() => {
        nextBtnRef.current?.click();
      }, 60000);
      setIntervalId(id);
    }
  };

  const handleStop = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };
  const handleStartAutoPageNavigationMap = () => {
    if (autoNextPageMap) {
      setAutoNextPageMap(false);
      handleStopMap();
    } else {
      setAutoNextPageMap(true);
      handleStartMap({ autoPageNext: true });
    }
  };
  const handleStartAutoPageNavigation = () => {
    if (autoNextPage) {
      setAutoNextPage(false);
      handleStop();
    } else {
      setAutoNextPage(true);
      handleStart({ autoPageNext: true });
    }
  };

  const handelAssetDisplayStatuesChange = (index) => {
    let newStatuesValue = [];
    assetDisplayStatues?.map((assetItem, assetIndex) => {
      if (index === assetIndex) {
        let item = assetItem;
        item = { ...item, show: !assetItem.show };
        newStatuesValue.push(item);
      } else {
        newStatuesValue.push(assetItem);
      }
    });
    setAssetDisplayStatues(newStatuesValue);
  };

  const handelAssetDisplaySelectAllStatuesChange = () => {
    let newStatuesValue = [];

    assetDisplayStatues?.map((assetItem, assetIndex) => {
      let item = assetItem;
      item = {
        ...item,
        show:
          assetDisplayStatues.length ===
            assetDisplayStatues.filter((item) => item.show).length
            ? false
            : true,
      };
      newStatuesValue.push(item);
    });
    setAssetDisplayStatues(newStatuesValue);
  };

  const handelChangeRowParPageValue = async (e) => {
    setRentedMapDataCurrontPage(1);
    if (!rentedMap) {
      localStorage.setItem("rowPerPage", e.target.value);
      setShowAssetCount(e.target.value);
    } else {
      localStorage.setItem("rowPerPage-Rental-Map", e.target.value);
      setShowAssetCountMap(e.target.value);
    }

    await resetPagination();
  };

  const handelStoreSelect = async (e) => {
    setStoreCodeSelect(e.target.value);
    await resetPagination();
    localStorage.setItem("StoreCode", e.target.value);
  };

  const [defaultProps, setDefaultProps] = React.useState({
    lat: "",
    lng: "",
  });

  const getOnlyOnRentMashinListings = async () => {
    setOnRentMashinListingsLoading(true);
    handleStopMap();
    let payLoead = {
      Filter: {
        All: null,
        Company: null,
        SerialNumber: null,
        Model: null,
        Year: null,
        UnitNumber: null,
        Location: null,
        Status: ["ON RENT"],
        Category: [],
        TargetSellDate: null,
        LastSMUDate: null,
        CompanyIds: settingValue,
        TargetSaleDateIsNull: null,
        ParentAssetIdIsNULL: null,
        IndAvailableAssets: false,
        MinYear: null,
        MaxYear: null,
        MinSMU: null,
        MaxSMU: null,
        Make: null,
        StatusId: null,
        OnlyInventoryFlag: null,
        MakeName: null,
        AssetIds: null,
        StoreIds: [],
      },
      PageSize: showAssetCountMap,
      PageNumber: rentedMapDataCurrontPage,
      Sort: "ChangeDate_desc",
      IncludeCount: true,
    };
    dashboardService
      .GetPagedAssetsAPI(payLoead)
      .then((response) => {
        if (response.statusCode === 200) {
          let center = "";

          response?.data.map((item) => {
            if (center.lat === undefined && center.lng === undefined) {
              center = {
                lat: item?.PositionLatitude,
                lng: item?.PositionLongitude,
              };
            }
          });
          handleStartMap({ autoPageNext: true });
          setRentedMapDataLength(response?.length);
          setDefaultProps(center);
          setSelectRentedMapItem({
            lat: center.lat,
            lng: center.lng,
            zoom: mapDefaultZoom,
          });
          setOnRentMashinListings(response?.data);
          setOnRentMashinListingsLoading(false);
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const handleMapView = () => {
    if (rentedMap) {
      setAutoNextPage(true);
      handleStart({ autoPageNext: true });
      handleStopMap();
      setRentedMapDataCurrontPage(1);
      setRentedMap(!rentedMap);
    } else {
      setAutoNextPage(false);
      handleStartMap({ autoPageNext: true });
      handleStop();
      setRentedMap(!rentedMap);
    }
  };
  const handelpaginationMap = () => {
    if (!onRentMashinListingsLoading) {
      if (rentedMapDataLength.TotalPages >= rentedMapDataCurrontPage + 1) {
        setRentedMapDataCurrontPage(rentedMapDataCurrontPage + 1);
      } else {
        setRentedMapDataCurrontPage(1);
      }
    }
  };
  const handelpaginationBackMap = () => {
    if (!onRentMashinListingsLoading) {
      if (rentedMapDataCurrontPage - 1 !== 0) {
        setRentedMapDataCurrontPage(rentedMapDataCurrontPage - 1);
      }
    }
  };
  const handleStartMap = (autoPageNext) => {
    if (autoNextPageMap || autoPageNext) {
      const id1 = setInterval(() => {
        nextBtnRefMap.current?.click();
      }, 60000);
      setIntervalIdMap(id1);
    }
  };
  const handleStopMap = () => {
    clearInterval(intervalIdMap);
    setIntervalIdMap(null);
  };

  React.useEffect(() => {
    if (rentedMap) {
      getOnlyOnRentMashinListings();
    }
  }, [rentedMap, rentedMapDataCurrontPage, showAssetCountMap]);

  const handelSetPopUPData = (item) => {
    setSelectPoPupMarker(item);
    setSelectRentedMapItem({
      lat: item.PositionLatitude,
      lng: item.PositionLongitude,
      zoom: 15,
    });
  };

  const handelCloseModel = (e) => {
    e.preventDefault();
    setSelectPoPupMarker(null);
  };

  const multipleSelect = (e) => {
    let newStatuesValue = [];
    assetDisplayStatues?.map((assetItem, assetIndex) => {
      if (e === assetItem?.AssetStatusId) {
        let item = assetItem;
        item = { ...item, show: !assetItem.show };
        newStatuesValue.push(item);
      } else {
        newStatuesValue.push(assetItem);
      }
    });
    setAssetDisplayStatues(newStatuesValue);
  };

  React.useEffect(() => {
    if (assetDisplayStatues) {
      handelSaveSelectStatuesModel();
    }
  }, [assetDisplayStatues]);

  const GetStoresCurrentRental = (id) => {
    setContractInfoLoader(true);
    dashboardService
      .GetPagedCurrentRentalAPI(id)
      .then((response) => {
        setContractInfoLoader(false);
        if (response.statusCode === 200) {
          setContractInfo(response.data);
        }
      })
      .catch((error) => {
        setContractInfoLoader(false);
      });
  };

  const GetImgCurrentRental = (id) => {
    setContractInfoLoader(true);
    dashboardService
      .GetPagedCurrentRentalImgAPI(id)
      .then((response) => {
        setContractInfoLoader(false);

        if (response.statusCode === 200) {
          setContractImgInfo(response.data);
        }
      })
      .catch((error) => {
        setContractInfoLoader(false);
      });
  };

  React.useEffect(() => {
    if (
      showDetails?.info &&
      showDetails?.model &&
      showDetails?.info?.CurrentRentalContractId
    ) {
      GetStoresCurrentRental(showDetails?.info?.CurrentRentalContractId);
    }

    if (showDetails?.info && showDetails?.model) {
      GetImgCurrentRental(showDetails?.info?.AssetId);
    }
  }, [showDetails?.info]);

  const onRentMashinListingsBySearch = (data) => {
    if (searchString) {
      const serchingdata = data.filter(
        (status) =>
          status?.SerialNumber?.toLowerCase()?.includes(
            searchString?.toLowerCase()
          ) ||
          status?.UnitNumber?.toLowerCase()?.includes(
            searchString?.toLowerCase()
          ) ||
          status?.Model?.toLowerCase()?.includes(searchString?.toLowerCase()) ||
          status?.MakeName?.toLowerCase()?.includes(searchString?.toLowerCase())
      );
      return serchingdata;
    } else {
      return data;
    }
  };

  return (
    <>
      <Modal
        open={selectStatuesModel}
        onClose={handelSelectStatuesModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#fff", fontWeight: "bold" }}
          >
            Display Statues
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    assetDisplayStatues.length ===
                    assetDisplayStatues.filter((item) => item.show).length
                  }
                  sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                      color: "#fff",
                    },
                  }}
                  onChange={handelAssetDisplaySelectAllStatuesChange}
                />
              }
              label={<Box sx={{ color: "#fff" }}>Select All</Box>}
            />
            {assetDisplayStatues?.map((assetItem, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assetItem?.show}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: "#fff",
                        },
                      }}
                      onChange={() => handelAssetDisplayStatuesChange(index)}
                    />
                  }
                  label={
                    <Box sx={{ color: "#fff" }}>
                      {assetItem?.AssetStatusName}
                    </Box>
                  }
                />
              );
            })}
          </FormGroup>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={handelSaveSelectStatuesModel}
            >
              Filter
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
              }}
              onClick={handelSelectStatuesModel}
            >
              Cancal
            </button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showDetails.model}
        onClose={handelSelectStatuesInfoModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={
            (showDetails?.info?.PositionLatitude !== undefined &&
              showDetails?.info?.PositionLongitude !== undefined) ||
              (contractInfo?.LocationLatitude !== undefined &&
                contractInfo?.LocationLongitude !== undefined)
              ? contractImgInfo?.url
                ? "90vw"
                : "60vw"
              : 400
          }
          sx={showInfoStyle}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            fontSize={"25px"}
            mb="10px"
            style={{ cursor: "pointer" }}
          >
            <b>Equipment Details</b>
            <CloseIcon
              sx={{ fontSize: "25px" }}
              onClick={handelSelectStatuesInfoModel}
            />
          </Box>
          {contractInfoLoader ? (
            <Box
              sx={{
                height: "60vh",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomLoader />
            </Box>
          ) : (
            <Grid container spacing={0}>
              <Grid
                item
                xs={
                  (showDetails?.info?.PositionLatitude !== undefined &&
                    showDetails?.info?.PositionLongitude !== undefined) ||
                    (contractInfo?.LocationLatitude !== undefined &&
                      contractInfo?.LocationLongitude !== undefined)
                    ? contractImgInfo?.url
                      ? 7
                      : 6
                    : 12
                }
              >
                <Box display={"flex"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Box>
                      {showDetails?.info?.MakeName && (
                        <Box>
                          <b>Make Name :</b> {showDetails?.info?.MakeName}
                        </Box>
                      )}
                      {showDetails?.info?.Model && (
                        <Box>
                          <b>Model : </b>
                          {showDetails?.info?.Model}
                        </Box>
                      )}

                      <Box>
                        <b>Unit Number : </b>
                        {showDetails?.info?.UnitNumber || "-"}
                      </Box>
                      <Box>
                        <b>Serial Number : </b>
                        {showDetails?.info?.SerialNumber || "-"}
                      </Box>
                      <Box>
                        <b>Current SMU: </b>
                        {showDetails?.info?.LastKnownSMU || "-"}{" "}
                        {showDetails?.info?.Smuunits}
                      </Box>

                      {showDetails?.info?.AssetDescription && (
                        <Box>
                          <b>Asset Description :</b>{" "}
                          {showDetails?.info?.AssetDescription}
                        </Box>
                      )}
                      <Box>
                        <b>Location :</b> {showDetails?.info?.Address1}
                        {showDetails?.info?.Address2
                          ? `, ${showDetails?.info?.Address2}`
                          : null}
                        {showDetails?.info?.City
                          ? `, ${showDetails?.info?.City}`
                          : null}
                        {showDetails?.info?.Province
                          ? `, ${showDetails?.info?.Province}`
                          : null}
                        {showDetails?.info?.PostalCode
                          ? `, ${showDetails?.info?.PostalCode}`
                          : null}
                      </Box>
                      <Box marginTop={"20px"}>
                        <Box fontWeight={"bold"} fontSize={"18px"}>
                          <b>Equipment Notes</b>
                        </Box>
                        <Box sx={{ whiteSpace: "pre-wrap" }}>
                          {showDetails?.info?.Note || "-"}
                        </Box>
                      </Box>
                    </Box>

                    <Box marginTop={"20px"}>
                      <Box fontWeight={"bold"} fontSize={"18px"}>
                        <b>Contract Details</b>
                      </Box>
                      {contractInfo ? (
                        <>
                          <Box>
                            <b>Contract Number : </b>
                            {contractInfo?.ContractNumber || "-"}
                          </Box>
                          <Box>
                            <b>Company Name : </b>
                            {contractInfo?.CompanyName || "-"}
                          </Box>
                          <Box>
                            <b>Store : </b>
                            {contractInfo?.StoreDescription || null}{" "}
                            {contractInfo?.StoreCode
                              ? `(${contractInfo?.StoreCode})`
                              : null}
                          </Box>
                          <Box>
                            <b>StartDate: </b>
                            {contractInfo?.ContractStartDate?.split("T")?.[0] ||
                              "-"}
                          </Box>
                          <Box>
                            <b>Expected Return Date: </b>
                            {contractInfo?.ExpectedReturnDateTime?.split(
                              "T"
                            )?.[0] || "-"}
                          </Box>
                          <Box>
                            <b>Location : </b>
                            {contractInfo?.LocationAddress1}
                            {contractInfo?.LocationAddress2
                              ? `, ${contractInfo?.LocationAddress2}`
                              : null}
                            {contractInfo?.LocationCity
                              ? `, ${contractInfo?.LocationCity}`
                              : null}
                            {contractInfo?.LocationProvince
                              ? `, ${contractInfo?.LocationProvince}`
                              : null}
                            {contractInfo?.LocationPostalCode
                              ? `, ${contractInfo?.LocationPostalCode}`
                              : null}
                          </Box>
                          <Box>
                            <b>Contact : </b>
                            {contractInfo?.FirstName} {contractInfo?.LastName}
                          </Box>
                          <Box>
                            <b>Sales Rep : </b>
                            {contractInfo?.SalesRep || "-"}
                          </Box>
                        </>
                      ) : (
                        "-"
                      )}
                    </Box>
                  </Box>

                  {contractImgInfo?.url && (
                    <img
                      src={contractImgInfo?.url}
                      style={{
                        width: "60%",
                        objectFit: "cover",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                      className="contractImgInfo_img"
                    />
                  )}
                </Box>
              </Grid>

              {((showDetails?.info?.PositionLatitude !== undefined &&
                showDetails?.info?.PositionLongitude !== undefined) ||
                (contractInfo?.LocationLatitude !== undefined &&
                  contractInfo?.LocationLongitude !== undefined)) && (
                  <Grid item xs={contractImgInfo?.url ? 5 : 6}>
                    <div>
                      <div
                        style={{
                          height: "calc(70vh - 40px)",
                          width: "100%",
                        }}
                      >
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyC6dUNz2Vry4dIPxtTmK5hnk3zGMV7Yq1M",
                          }}
                          defaultCenter={{
                            lat:
                              showDetails?.info?.PositionLatitude ||
                              Number(contractInfo?.LocationLatitude),
                            lng:
                              showDetails?.info?.PositionLongitude ||
                              Number(contractInfo?.LocationLongitude),
                          }}
                          defaultZoom={12}
                          zoom={12}
                          center={{
                            lat:
                              showDetails?.info?.PositionLatitude ||
                              Number(contractInfo?.LocationLatitude),
                            lng:
                              showDetails?.info?.PositionLongitude ||
                              Number(contractInfo?.LocationLongitude),
                          }}
                          // options={{
                          //   streetViewControl: true,
                          //   mapTypeId: "satellite",
                          // }}
                          options={{
                            mapTypeControl: true,
                            streetViewControlOptions: true,
                            streetViewControl: true,
                            draggable: true, // make map draggable
                            zoomControlOptions: { position: 12 },
                            keyboardShortcuts: false, // disable keyboard shortcuts
                            scaleControl: true, // allow scale controle
                            scrollwheel: true, // allow scroll wheel
                          }}
                        >
                          {showDetails?.info?.PositionLatitude &&
                            showDetails?.info?.PositionLongitude && (
                              <AnyReactComponent
                                lat={showDetails?.info?.PositionLatitude}
                                lng={showDetails?.info?.PositionLongitude}
                                item={showDetails?.info}
                                text="My Marker"
                                onClick={() => { }}
                                selectPoPupMarker={selectPoPupMarker}
                                closePopUp={(e) => handelCloseModel(e)}
                              />
                            )}
                          {contractInfo?.LocationLatitude &&
                            contractInfo?.LocationLongitude && (
                              <AnyReactComponent
                                lat={contractInfo?.LocationLatitude}
                                lng={contractInfo?.LocationLongitude}
                                item={contractInfo}
                                blueImg
                                text="My Marker"
                                onClick={() => { }}
                                selectPoPupMarker={selectPoPupMarker}
                                closePopUp={(e) => handelCloseModel(e)}
                              />
                            )}
                        </GoogleMapReact>
                      </div>
                    </div>
                  </Grid>
                )}
            </Grid>
          )}
        </Box>
      </Modal>

      <Box className="Dashboard_Box">
        {/* ------------------ small Screen To Md Screen design --------------- */}
        <Box className="Dashboard_Sm_Screen">
          <Box className="Dashboard_Right_Navigation_Bar">
            <Box className="Dashboard_Navigation_Card_Box">
              {AssetTypeList?.map((item, index) => {
                return (
                  <>
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                      sx={{ background: "#ff000000" }}
                    >
                      <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        sx={{ margin: "0px", padding: "0px" }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_Sm"
                          sx={{ background: item?.AssetColorCode }}
                        >
                          <Box className="Dashboard_Navigation_Card_text">
                            {item?.AssetTypeName}
                          </Box>
                          <Box className="Dashboard_Navigation_Card_text text-bold">
                            {item?.AssetCount}
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {AssetList?.map((AssetListItem) => {
                          return (
                            AssetListItem?.Type === item?.AssetTypeName && (
                              <DashboardCardBox item={AssetListItem} />
                            )
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
            </Box>
            <Box className="Dashboard_Navigation_Setting_Box">
              <Box className="Dashboard_Navigation_Page_Text">
                Page{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  10
                </span>{" "}
                of{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  59
                </span>
              </Box>
              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <WestIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <PauseIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <EastIcon />
                </Box>
              </Box>

              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Option_close_Button">
                  <Box className="Dashboard_Navigation_close_Button">
                    <SettingsIcon sx={{ fontSize: "16px" }} />
                  </Box>
                  <span style={{ marginLeft: "5px" }}>Settings</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* ------------------ md To Big Screen design --------------- */}
        <Box className="Dashboard_Big_Screen">
          <Grid container spacing={1}>
            <Grid item xs={showSideBar ? 10 : 12}>
              {rentedMap ? (
                onRentMashinListingsLoading ? (
                  <div
                    className="dashboard_Box"
                    style={{ display: "flex", overflow: "auto", width: "100%" }}
                  >
                    <Box
                      sx={{
                        height: "90vh",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  </div>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={3} className="dashboard_Box_ON_RENT">
                      <Box sx={{ mb: "60px", minWidth: "300px" }}>
                        <Box>
                          <Box className="Dashboard_Asset_Heading">
                            ON RENT ({rentedMapDataLength?.TotalRecords ?? 0})
                          </Box>
                          <Box className="Dashboard_Asset_Heading" mx={"7px"}>
                            <TextField
                              fullWidth
                              inputProps={{
                                style: { padding: "10px" },
                              }}
                              onChange={(e) => {
                                setSearchString(e.target.value);
                              }}
                              placeholder="Serach by Unit Number, Model, Make"
                            />
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              {onRentMashinListingsBySearch(
                                onRentMashinListings
                              ).map((item) => {
                                return (
                                  <DashboardCardBox
                                    item={item}
                                    navigationColor={"e8ffe8"}
                                    onClick={(item) =>
                                      setSelectRentedMapItem({
                                        lat: item?.PositionLatitude,
                                        lng: item?.PositionLongitude,
                                        zoom: 20,
                                      })
                                    }
                                    className="cursor_pointer"
                                  />
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <div>
                        <div
                          style={{
                            height: "calc(100vh - 40px)",
                            width: "100%",
                          }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyC6dUNz2Vry4dIPxtTmK5hnk3zGMV7Yq1M",
                            }}
                            defaultCenter={{
                              lat: defaultProps.lat,
                              lng: defaultProps.lag,
                            }}
                            defaultZoom={6}
                            zoom={selectRentedMapItem.zoom}
                            center={{
                              lat: selectRentedMapItem.lat,
                              lng: selectRentedMapItem.lng,
                            }}
                            // options={{
                            //   streetViewControl: true,
                            //   mapTypeId: "satellite",
                            // }}
                            options={{
                              mapTypeControl: true,
                              streetViewControlOptions: true,
                              streetViewControl: true,
                              draggable: true, // make map draggable
                              zoomControlOptions: { position: 9 },
                              keyboardShortcuts: false, // disable keyboard shortcuts
                              scaleControl: true, // allow scale controle
                              scrollwheel: true, // allow scroll wheel
                            }}
                          >
                            {onRentMashinListings.map((item) => {
                              return (
                                item?.PositionLatitude !== undefined &&
                                item?.PositionLongitude !== undefined && (
                                  <AnyReactComponent
                                    lat={item?.PositionLatitude}
                                    lng={item?.PositionLongitude}
                                    item={item}
                                    text="My Marker"
                                    onClick={() => handelSetPopUPData(item)}
                                    selectPoPupMarker={selectPoPupMarker}
                                    closePopUp={(e) => handelCloseModel(e)}
                                  />
                                )
                              );
                            })}
                          </GoogleMapReact>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )
              ) : (
                <div
                  className="dashboard_Box"
                  style={{ display: "flex", overflow: "auto", width: "100%" }}
                >
                  {dashboardLoader || assetCategorizationSummaryDMSLoader ? (
                    <Box
                      sx={{
                        height: "90vh",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomLoader />
                    </Box>
                  ) : assetList?.selectAssetDetails &&
                    Object.keys(assetList?.selectAssetDetails).length === 0 ? (
                    showParticularCategory?.showOnlyOneCategory ? (
                      <>
                        {AssetTypeList?.filter(
                          (item) =>
                            item?.AssetTypeId ===
                            showParticularCategory?.categoryID
                        ).map((item) => {
                          return (
                            <Box sx={{ mb: "60px", minWidth: "100%" }}>
                              <Box>
                                <Box className="Dashboard_Asset_Heading">
                                  {item?.AssetTypeName} (
                                  {totalAssetsRecode?.[item?.AssetTypeName]
                                    ?.totalAssetsRecode ?? 0}
                                  )
                                </Box>
                                {/* <Grid container spacing={2}> */}
                                <Box display={"flex"} flexWrap={"wrap"}>
                                  {assetStatusesDetails?.[
                                    item?.AssetTypeName
                                  ]?.data.map((item) => {
                                    return (
                                      <DashboardBigCardBox
                                        item={item}
                                        navigationColor={item?.navigationColor}
                                      />
                                    );
                                  })}
                                </Box>
                                {/* </Grid> */}
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {AssetTypeList?.map((item) => {
                          return (totalAssetsRecode?.[item?.AssetTypeName]?.totalAssetsRecode && totalAssetsRecode?.[item?.AssetTypeName]?.totalAssetsRecode > 0) ? (
                            <Box sx={{ mb: "60px", minWidth: "300px" }}>
                              <Box>
                                <Box className="Dashboard_Asset_Heading">
                                  {item?.AssetTypeName} (
                                  {totalAssetsRecode?.[item?.AssetTypeName]
                                    ?.totalAssetsRecode ?? 0}
                                  )
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {assetStatusesDetails?.[
                                      item?.AssetTypeName
                                    ]?.data.map((item) => {
                                      return (
                                        <DashboardCardBox
                                          item={item}
                                          navigationColor={
                                            item?.navigationColor
                                          }
                                        />
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          ) : null;
                        })}
                      </>
                    )
                  ) : !assetList?.TierData ? (
                    showParticularCategory?.showOnlyOneCategory ? (
                      <>
                        {assetList?.selectAssetDetails?.CategoryDetails?.filter(
                          (item) =>
                            item?.CategoryId ===
                            showParticularCategory?.categoryID
                        )?.map((CategoryItem) => {
                          return (
                            <Box sx={{ mb: "60px", minWidth: "100%" }}>
                              <Box>
                                <Box className="Dashboard_Asset_Heading">
                                  {CategoryItem?.CategoryName} (
                                  {totalAssetsRecode?.[
                                    CategoryItem?.CategoryName
                                  ]?.totalAssetsRecode ?? 0}
                                  )
                                </Box>
                                <Box display={"flex"} flexWrap={"wrap"}>
                                  {assetStatusesDetails?.[
                                    CategoryItem?.CategoryName
                                  ]?.data.map((item) => {
                                    return (
                                      <DashboardBigCardBox
                                        item={item}
                                        navigationColor={item?.navigationColor}
                                      />
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {assetList?.selectAssetDetails?.CategoryDetails?.map(
                          (CategoryItem) => {
                            return (totalAssetsRecode?.[CategoryItem?.CategoryName]?.totalAssetsRecode && totalAssetsRecode?.[CategoryItem?.CategoryName]?.totalAssetsRecode > 0) ? (
                              <Box sx={{ mb: "60px", minWidth: "300px" }}>
                                <Box>
                                  <Box className="Dashboard_Asset_Heading">
                                    {CategoryItem?.CategoryName} (
                                    {totalAssetsRecode?.[
                                      CategoryItem?.CategoryName
                                    ]?.totalAssetsRecode ?? 0}
                                    )
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      {assetStatusesDetails?.[
                                        CategoryItem?.CategoryName
                                      ]?.data.map((item) => {
                                        return (
                                          <DashboardCardBox
                                            item={item}
                                            navigationColor={
                                              item?.navigationColor
                                            }
                                          />
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            ) : null;
                          }
                        )}
                      </>
                    )
                  ) : showParticularCategory?.showOnlyOneCategory ? (
                    <>
                      {assetList?.[`Tier${assetList?.tierCount}Details`]
                        ?.filter(
                          (item) =>
                            item?.Tier1Id === showParticularCategory?.categoryID
                        )
                        ?.map((TierItem) => {
                          return (
                            <Box sx={{ mb: "60px", minWidth: "100%" }}>
                              <Box>
                                <Box className="Dashboard_Asset_Heading">
                                  {
                                    TierItem?.[
                                    `Tier${assetList?.tierCount}Name`
                                    ]
                                  }{" "}
                                  (
                                  {totalAssetsRecode?.[
                                    TierItem?.[
                                    `Tier${assetList?.tierCount}Name`
                                    ]
                                  ]?.totalAssetsRecode ?? 0}
                                  )
                                </Box>
                                <Box display={"flex"} flexWrap={"wrap"}>
                                  {assetStatusesDetails?.[
                                    TierItem?.[
                                    `Tier${assetList?.tierCount}Name`
                                    ]
                                  ]?.data.map((item) => {
                                    return (
                                      <DashboardBigCardBox
                                        item={item}
                                        navigationColor={item?.navigationColor}
                                      />
                                    );
                                  })}
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {assetList?.[`Tier${assetList?.tierCount}Details`]?.map(
                        (TierItem) => {
                          return (totalAssetsRecode?.[TierItem?.[`Tier${assetList?.tierCount}Name`]]?.totalAssetsRecode && totalAssetsRecode?.[TierItem?.[`Tier${assetList?.tierCount}Name`]]?.totalAssetsRecode > 0) ? (
                            <Box sx={{ mb: "60px", minWidth: "300px" }}>
                              <Box>
                                <Box className="Dashboard_Asset_Heading">
                                  {
                                    TierItem?.[
                                    `Tier${assetList?.tierCount}Name`
                                    ]
                                  }{" "}
                                  (
                                  {totalAssetsRecode?.[
                                    TierItem?.[
                                    `Tier${assetList?.tierCount}Name`
                                    ]
                                  ]?.totalAssetsRecode ?? 0}
                                  )
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {assetStatusesDetails?.[
                                      TierItem?.[
                                      `Tier${assetList?.tierCount}Name`
                                      ]
                                    ]?.data.map((item) => {
                                      return (
                                        <DashboardCardBox
                                          item={item}
                                          navigationColor={
                                            item?.navigationColor
                                          }
                                        />
                                      );
                                    })}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          ) : null;
                        }
                      )}
                    </>
                  )}
                </div>
              )}
            </Grid>
            {showSideBar &&
              (!sideBarMenu ? (
                <>
                  <Grid item xs={2} className="Dashboard_Right_Navigation_Bar">
                    <Box className="Dashboard_V2_Navigation_Card_Box">
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img src="./logo.png" alt="" className="logo-light" />
                      </Box>

                      <Box className="Dashboard_Navigation_Header">
                        <Box
                          display={"flex"}
                          width={"-webkit-fill-available"}
                          justifyContent={"space-between"}
                        >
                          <Tooltip
                            title={
                              !rentedMap
                                ? "Switch to Rental Map"
                                : "Switch to Rental Board"
                            }
                            placement="top"
                          >
                            <Box
                              className={`icon-container ${rentedMap ? "flipped" : ""
                                }`}
                              onClick={handleMapView}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <Box className="icon">
                                <img
                                  src={!rentedMap ? RentalBord : RNTWO}
                                  width={"100%"}
                                />
                              </Box>
                            </Box>
                          </Tooltip>

                          <Tooltip
                            title={"Switch to Delivery Receipt"}
                            placement="top"
                          >
                            <Box
                              onClick={() => {
                                setOpenSwitchModel(true);
                              }}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={DeliveryReceipt} width={"100%"} />
                            </Box>
                          </Tooltip>

                          <Tooltip
                            title={"Switch to Rental Board V1"}
                            placement="top"
                          >
                            <Box
                              onClick={() => {
                                setOpenSwitchV1Model(true);
                              }}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={RNONE} width={"100%"} />
                            </Box>
                          </Tooltip>

                          <Tooltip title={"Log out"} placement="top">
                            <Box
                              onClick={handleClickOpen}
                              sx={{ cursor: "pointer" }}
                              width={"2vw"}
                              height={"2vw"}
                              padding={"5px"}
                              bgcolor={"#ff0000"}
                              borderRadius={"0.5vw"}
                            >
                              <img src={LogOutIcon} width={"100%"} />
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box className="Dashboard_Navigation_Header">
                        {(!sideBarMenu ||
                          showParticularCategory?.showOnlyOneCategory) && (
                            <Box
                              className="Dashboard_Navigation_Back_Btn"
                              style={
                                !assetList?.ShowBackBTN
                                  ? { opacity: "0.5", cursor: "not-allowed" }
                                  : { opacity: "1", cursor: "pointer" }
                              }
                              onClick={() => {
                                showParticularCategory?.showOnlyOneCategory
                                  ? setShowParticularCategory({
                                    categoryID: null,
                                    categoryName: null,
                                    showOnlyOneCategory: false,
                                  })
                                  : handelSelectAssetTypeListBackFun();
                              }}
                            >
                              <ArrowBackIcon />
                            </Box>
                          )}
                        <Tooltip
                          title={
                            showParticularCategory?.showOnlyOneCategory
                              ? showParticularCategory?.categoryName
                              : assetList?.ShowTitle
                          }
                          placement="top"
                        >
                          <Box
                            sx={{
                              color: "#fff",
                              fontWeight: "bold",
                              p: "0 15px",
                            }}
                            className="oneLineCSS"
                          >
                            {showParticularCategory?.showOnlyOneCategory
                              ? showParticularCategory?.categoryName
                              : assetList?.ShowTitle}
                          </Box>
                        </Tooltip>
                        <Box sx={{ display: "flex" }}></Box>
                      </Box>
                      {assetCategorizationSummaryDMSLoader ? (
                        <Box
                          sx={{
                            height: "80vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CustomLoader />
                        </Box>
                      ) : assetList?.selectAssetDetails &&
                        Object.keys(assetList?.selectAssetDetails).length ===
                        0 ? (
                        showParticularCategory?.showOnlyOneCategory ?
                          null : (
                            <>
                              {AssetTypeList?.map((item) => {
                                return (totalAssetsRecode?.[item?.AssetTypeName]?.totalAssetsRecode && totalAssetsRecode?.[item?.AssetTypeName]?.totalAssetsRecode > 0) ? (
                                  <Box
                                    className="Dashboard_Navigation_Card"
                                    sx={{
                                      background: "#009688",
                                    }}
                                    onClick={() =>
                                      item?.CategoryDetails?.length > 0
                                        ? handelSelectAssetTypeList(item, {
                                          Type: "AssetList",
                                        })
                                        : handelShowOnlyOneCategory(item, {
                                          Type: "AssetList",
                                        })
                                    }
                                  >
                                    <Tooltip
                                      title={item?.AssetTypeName}
                                      placement="top"
                                    >
                                      <Box className="Dashboard_Navigation_Card_text">
                                        {item?.AssetTypeName}
                                      </Box>
                                    </Tooltip>
                                  </Box>
                                ) : null;
                              })}
                            </>
                          )
                      ) : !assetList?.TierData ? (
                        showParticularCategory?.showOnlyOneCategory ?
                          null : (
                            <>
                              {assetList?.selectAssetDetails?.CategoryDetails?.map(
                                (CategoryItem) => {
                                  return (totalAssetsRecode?.[CategoryItem?.CategoryName]?.totalAssetsRecode && totalAssetsRecode?.[CategoryItem?.CategoryName]?.totalAssetsRecode > 0) ? (
                                    <Box
                                      className="Dashboard_Navigation_Card"
                                      sx={{
                                        background: "#009688",
                                      }}
                                      onClick={() =>
                                        CategoryItem?.Tier1Details?.length > 0
                                          ? handelSelectAssetTypeList(
                                            CategoryItem,
                                            {
                                              Type: "CategoryDetails",
                                            }
                                          )
                                          : handelShowOnlyOneCategory(
                                            CategoryItem,
                                            {
                                              Type: "CategoryDetails",
                                            }
                                          )
                                      }
                                    >
                                      <Tooltip
                                        title={CategoryItem?.CategoryName}
                                        placement="top"
                                      >
                                        <Box className="Dashboard_Navigation_Card_text">
                                          {CategoryItem?.CategoryName}
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  ) : null;
                                }
                              )}
                            </>
                          )
                      ) : showParticularCategory?.showOnlyOneCategory ?
                        null : (
                          <>
                            {assetList?.[
                              `Tier${assetList?.tierCount}Details`
                            ]?.map((TierItem) => {
                              return (totalAssetsRecode?.[TierItem?.[`Tier${assetList?.tierCount}Name`]]?.totalAssetsRecode && totalAssetsRecode?.[TierItem?.[`Tier${assetList?.tierCount}Name`]]?.totalAssetsRecode > 0) ? (
                                <Box
                                  className="Dashboard_Navigation_Card"
                                  sx={{
                                    background: "#009688",
                                  }}
                                  onClick={() =>
                                    TierItem?.Tier2Details?.length > 0
                                      ? handelSelectAssetTypeList(TierItem, {
                                        Type: "TierData",
                                      })
                                      : handelShowOnlyOneCategory(TierItem, {
                                        Type: "TierData",
                                      })
                                  }
                                >
                                  <Tooltip
                                    title={
                                      TierItem?.[
                                      `Tier${assetList?.tierCount}Name`
                                      ]
                                    }
                                    placement="top"
                                  >
                                    <Box className="Dashboard_Navigation_Card_text">
                                      {
                                        TierItem?.[
                                        `Tier${assetList?.tierCount}Name`
                                        ]
                                      }
                                    </Box>
                                  </Tooltip>
                                </Box>
                              ) : null;
                            })}
                          </>
                        )}
                    </Box>

                    <Box sx={{ marginBottom: "10px" }}>
                      <Box sx={{ textAlign: "start", color: "#fff", mb: 1 }}>
                        Statuses
                      </Box>
                      <StyledSelect
                        placeholder="Select Statuses"
                        multiple
                        displayEmpty
                        value={assetDisplayStatues
                          .filter((item) => item.show)
                          .map((item) => item.AssetStatusId)}
                        MenuProps={MenuProps}
                        input={
                          <OutlinedInput
                            label="Name"
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#fff0", // Change border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#fff0", // Change border color on hover
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#fff0", // Change border color when focused
                              },
                              "& .MuiInputBase-input": {
                                color: "#fff", // Change text color
                              },
                              "& .MuiSelect-icon": {
                                color: "#fff", // Change dropdown arrow color
                              },
                            }}
                          />
                        }
                        sx={{
                          color: "#fff",
                          border: "1px solid #fff",
                          outline: "none",
                          fontSize: "13px",
                          padding: "0px",
                          width: "-webkit-fill-available",
                        }}
                        disableUnderline
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            Select Statuses
                          </Box>
                        )}
                        inputProps={{
                          placeholder: "Select Statuses",
                          "aria-label": "Without label",
                        }}
                      >
                        <MenuItem
                          key={-1}
                          value={[]}
                          onClick={handelAssetDisplaySelectAllStatuesChange}
                        >
                          <Checkbox
                            checked={
                              assetDisplayStatues.length ===
                              assetDisplayStatues.filter((item) => item.show)
                                .length
                            }
                          // sx={{
                          //   [`&, &.${checkboxClasses.checked}`]: {
                          //     color: "#fff",
                          //   },
                          // }}
                          />
                          SELECT ALL
                        </MenuItem>
                        {assetDisplayStatues.map(
                          ({ AssetStatusId, AssetStatusName, show }) => (
                            <MenuItem
                              key={AssetStatusId}
                              value={AssetStatusId}
                              style={getStyles(
                                AssetStatusName,
                                assetDisplayStatues,
                                theme
                              )}
                              onClick={() => multipleSelect(AssetStatusId)}
                            >
                              <Checkbox
                                checked={show}
                              // sx={{
                              //   [`&, &.${checkboxClasses.checked}`]: {
                              //     color: "#",
                              //   },
                              // }}
                              />
                              {AssetStatusName}
                            </MenuItem>
                          )
                        )}
                      </StyledSelect>
                    </Box>
                  </Grid>
                </>
              ) : (
                <Grid item xs={2} className="Dashboard_Right_Navigation_Bar">
                  <Box
                    className="Dashboard_Navigation_Card_Box"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                          src="./logo-light.svg"
                          alt=""
                          className="logo-light"
                        />
                      </Box>
                      <Box>
                        <Box className="Dashboard_Navigation_Header">
                          {!sideBarMenu && (
                            <Box
                              className="Dashboard_Navigation_Back_Btn"
                              style={{ opacity: "0.5", cursor: "not-allowed" }}
                              onClick={handelSelectAssetTypeListBackFun}
                            >
                              <ArrowBackIcon />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <Box sx={{ marginBottom: "10px" }}>
                          <Box sx={{ textAlign: "start", color: "#fff" }}>
                            Stores
                          </Box>
                          <Box className="custom-select">
                            <select
                              className="select_Box"
                              value={storeCodeSelect}
                              onChange={handelStoreSelect}
                            >
                              <option className="select_option" value={""}>
                                Select Store
                              </option>
                              ;
                              {storeList?.map((storeItem, index) => {
                                return (
                                  <option
                                    className="select_option"
                                    value={storeItem?.StoreId}
                                  >
                                    {storeItem?.Description}
                                  </option>
                                );
                              })}
                            </select>
                          </Box>
                        </Box>
                        <Box sx={{ textAlign: "start", color: "#fff" }}>
                          Recoeds per Column
                        </Box>
                        <Box className="custom-select">
                          <select
                            className="select_Box"
                            value={
                              !rentedMap ? showAssetCount : showAssetCountMap
                            }
                            onChange={handelChangeRowParPageValue}
                          >
                            <option className="select_option" value="10">
                              10
                            </option>
                            <option className="select_option" value="20">
                              20
                            </option>
                            <option className="select_option" value="50">
                              50
                            </option>
                            <option className="select_option" value="100">
                              100
                            </option>
                            <option className="select_option" value="200">
                              200
                            </option>
                            <option className="select_option" value="99999999">
                              All
                            </option>
                          </select>
                        </Box>
                      </Box>
                      {/* <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => setSelectStatuesModel(true)}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          Select Statuses
                        </Box>
                      </Box> */}

                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={handleMapView}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          {!rentedMap
                            ? "Switch to Rental Map"
                            : "Switch to Rental Board"}
                        </Box>
                      </Box>

                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => {
                          setOpenSwitchModel(true);
                        }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{
                            color: "#fff",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          <img
                            src="./switch.svg"
                            alt=""
                            width={"20px"}
                            style={{ marginRight: "10px" }}
                          />
                          Switch to Delivery Receipt
                        </Box>
                      </Box>

                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={() => {
                          setOpenSwitchV1Model(true);
                        }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{
                            color: "#fff",
                            cursor: "pointer",
                            display: "flex",
                          }}
                        >
                          <img
                            src="./switch.svg"
                            alt=""
                            width={"20px"}
                            style={{ marginRight: "10px" }}
                          />
                          Switch to Rental Board V1
                        </Box>
                      </Box>

                      <Box
                        className="Dashboard_Navigation_Card"
                        sx={{ background: "red", justifyContent: "center" }}
                        onClick={handleClickOpen}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_text"
                          sx={{ color: "#fff", cursor: "pointer" }}
                        >
                          Log Out
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
        {/*  --------------- Footer ------------------- */}
        <Box className="DashboardComponent_Footer">
          <Grid container spacing={0} sx={{ alignItems: "center" }}>
            <Grid item xs={10}>
              <Box className="Dashboard_Navigation_Setting_Box_Big_Screen">
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  {!rentedMap ? (
                    <Box
                      className="Dashboard_Navigation_Button cursor_pointer"
                      onClick={handleStartAutoPageNavigation}
                    >
                      {autoNextPage ? <PauseIcon /> : <PlayArrowIcon />}
                    </Box>
                  ) : (
                    <Box
                      className="Dashboard_Navigation_Button cursor_pointer"
                      onClick={handleStartAutoPageNavigationMap}
                    >
                      {autoNextPageMap ? <PauseIcon /> : <PlayArrowIcon />}
                    </Box>
                  )}
                </Box>
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  {!rentedMap ? (
                    <>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationBack}
                      >
                        <KeyboardArrowLeftIcon />
                      </Box>
                      <Box className="Dashboard_Navigation_Page_Text">
                        Page{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {currentPage}
                        </span>{" "}
                        of{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {totalPage}
                        </span>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpagination}
                        ref={nextBtnRef}
                      >
                        <KeyboardArrowRightIcon />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationBackMap}
                      >
                        <KeyboardArrowLeftIcon />
                      </Box>
                      <Box className="Dashboard_Navigation_Page_Text">
                        Page{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {rentedMapDataLength?.PageNumber}
                        </span>{" "}
                        of{" "}
                        <span className="Dashboard_Navigation_Page_Text_Highlights">
                          {rentedMapDataLength?.TotalPages}
                        </span>
                      </Box>
                      <Box
                        className="Dashboard_Navigation_Button cursor_pointer"
                        onClick={handelpaginationMap}
                        ref={nextBtnRefMap}
                      >
                        <KeyboardArrowRightIcon />
                      </Box>
                    </>
                  )}
                </Box>
                <Box></Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="Dashboard_Option_close_Button" />
                {!!showSideBar && !rentedMap && (
                  <Box>
                    {!sideBarMenu ? (
                      <Box
                        className="Dashboard_Option_close_Button"
                        onClick={() => setSideBarMenu(true)}
                      >
                        <Box className="Dashboard_Navigation_close_Button">
                          <SettingsIcon sx={{ fontSize: "16px" }} />
                        </Box>
                        <span style={{ margin: "0 10px" }}>Settings</span>
                      </Box>
                    ) : (
                      <Box
                        className="Dashboard_Option_close_Button"
                        onClick={() => setSideBarMenu(false)}
                      >
                        <Box className="Dashboard_Navigation_close_Button">
                          <CloseIcon sx={{ fontSize: "16px" }} />
                        </Box>
                        <span style={{ margin: "0 10px" }}>Close</span>
                      </Box>
                    )}
                  </Box>
                )}
                {showSideBar ? (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowRightIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowLeftIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={openSwitchModel}
          onClose={() => setOpenSwitchModel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to switch to Delivery Receipt ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setOpenSwitchModel(false)}
            >
              No
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={Delivery_Receipt_Navigate}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSwitchV1Model}
          onClose={() => setOpenSwitchV1Model(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to switch to Rental Board ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setOpenSwitchV1Model(false)}
            >
              No
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={Dashboard_Navigate}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to logout ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={handleClose}
            >
              Cancal
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={logOut}
            >
              Log Out
            </button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default DashboardV2Component;

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
