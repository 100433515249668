export const PRODURL = 'https://api.vizybility.net'
export const TestURL = 'https://vizybility-api-dev.azurewebsites.net'

const BASE_URL = process.env.DEV_BASE_URL || PRODURL
// ? Machine List
const config = {
  STS_SIGNIN_URL: 'https://sts.vizybility.net/connect/token',
  STS_STATUSES_URL: BASE_URL + '/Lookup/GetAssetStatuses',
  STS_PAGED_ASSETS_URL: BASE_URL + '/Asset/GetPagedAssets',
  STS_PAGED_ASSETS_RENTAL_URL: BASE_URL + '/Asset/GetPagedRentalBoardAssets',
  STS_SUMMARY_URL: BASE_URL + '/Rental/GetPagedDeliveryReceiptSummary',
  STS_CONTRACT_URL: BASE_URL + '/Rental/GetContractHeaderSummaryById',
  sts_GETSTARES: BASE_URL + '/Lookup/GetStores',
  STS_DMS:
    BASE_URL + '/AssetCategorization/GetTierWiseAssetCategorizationSummaryDMS',
  SETTING_KEY: BASE_URL + '/Settings/GetSettingByKey?key=DefaultCompanyId',
  PICTURES_URL: BASE_URL + '/Pictures/GetMainPictureByAssetId',
}

export { config }
