import "./App.css";
import "./Style/Dashboard.css";
import "./Style/Login.css";
import "./Style/Home.css";
import Login from "./Page/Login/login";
import Dashboard from "./Page/Dashboard/dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Router/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Page/Home/Home";
import DeliveryReceipt from "./Page/DeliveryReceipt/dashboard";
import DashboardV2 from "./Page/DashboardV2/dashboardV2";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/home" element={<ProtectedRoute Component={Home} />}></Route>

          <Route
            exact
            path="/dashboard"
            element={<ProtectedRoute Component={Dashboard} />}
          ></Route>

          <Route
            exact
            path="/dashboard-v2"
            element={<ProtectedRoute Component={DashboardV2} />}
          ></Route>

          <Route
            exact
            path="/delivery-receipt"
            element={<ProtectedRoute Component={DeliveryReceipt} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
