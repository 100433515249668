import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import PauseIcon from "@mui/icons-material/Pause";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import LogOutIcon from "../../asset/icon/logout-icon.svg";
import RNONE from "../../asset/icon/Rental-Board-v1.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  checkboxClasses,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Modal,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { dashboardService } from "../../Services/dashboardService";
import { json, useNavigate } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CustomLoader from "../CustomLoader/customLoader";
import { setLocalStorageData } from "../../Utils/helpers";
import { SELECT_BOARD } from "../../Utils/contasts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const DeliveryReceiptComponent = () => {
  const [intervalId, setIntervalId] = React.useState(null);
  const [settingValue, setSettingValue] = React.useState(null);
  const [totalAssetsRecode, setTotalAssetsRecode] = React.useState({});
  const [settingValueLoader, setSettingValueLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectStatuesModel, setSelectStatuesModel] = React.useState(false);
  const [storeList, setStoreList] = React.useState([]);
  const [openSwitchModel, setOpenSwitchModel] = React.useState(false);
  const [navigationColorColor, setNavigationColorColor] = React.useState([
    {
      color: "#863B96",
      use: false,
    },
    {
      color: "#00b33c",
      use: false,
    },
    {
      color: "#FFC80F",
      use: false,
    },
    {
      color: "#ac7339",
      use: false,
    },
    {
      color: "#1a75ff",
      use: false,
    },
    {
      color: "#fccde2",
      use: false,
    },
    {
      color: "#a7ff83",
      use: false,
    },
    {
      color: "#88bef5",
      use: false,
    },
    {
      color: "#e8ffe8",
      use: false,
    },
    {
      color: "#a56cb4",
      use: false,
    },
    {
      color: "#775ada",
      use: false,
    },
    {
      color: "#278ea5",
      use: false,
    },
    {
      color: "#ffb174",
      use: false,
    },
  ]);
  const navigate = useNavigate();
  const [showAssetCount, setShowAssetCount] = React.useState(
    localStorage.getItem("rowPerPageInDR") ?? 10
  );
  const [totalPage, setTotalPage] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [storeCodeSelect, setStoreCodeSelect] = React.useState(
    localStorage.getItem("StoreCodeInDR") ?? ""
  );
  const [autoNextPage, setAutoNextPage] = React.useState(true);
  const [timeAutoNextPage, setTimeAutoNextPage] = React.useState(false);
  const [
    assetCategorizationSummaryDMSLoader,
    setAssetCategorizationSummaryDMSLoader,
  ] = React.useState(true);
  const [loader, setLoader] = React.useState({
    AssetCategorizationSummaryDMSLoader: true,
    AssetStatusesLoader: true,
  });
  const [expanded, setExpanded] = React.useState(false);
  const [sideBarMenu, setSideBarMenu] = React.useState(false);
  const [assetList, setAssetList] = React.useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });
  const [dashboardLoader, setDashboardLoader] = React.useState(true);
  const [AssetTypeList, setAssetTypeList] = React.useState([]);
  const [assetStatuses, setAssetStatuses] = React.useState(
    JSON.parse(localStorage.getItem("DisplayStatuesInDR")) ?? []
  );
  const [assetDisplayStatues, setAssetDisplayStatues] = React.useState([]);
  const [assetStatusesDetails, setAssetStatusesDetails] = React.useState([]);
  const [assetStatusesListPagination, setAssetStatusesListPagination] =
    React.useState([]);
  const [showSideBar, setShowSideBar] = React.useState(true);
  const [DisplayStatuesLocalStorage, setDisplayStatuesLocalStorage] =
    React.useState(JSON.parse(localStorage.getItem("DisplayStatuesInDR")));
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const AssetList = [
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["Available"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "yellow",
        description: ["Down", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Contract",
      NavigatorColor: "#863B96",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "AVAILABLE",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "On Work Order",
      NavigatorColor: "#009688",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",
      CategoryData: {
        Color: "#009688",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
    {
      Name: "05TA-06",
      Description: "TANT ACCESSORIES",
      Type: "LIGHT DAMAGE",
      NavigatorColor: "#FFC80F",

      CategoryData: {
        Color: "orange",
        description: ["On Rent", "Jones", "4/18"],
      },
    },
  ];

  const Dashboard_Navigate = () => {
    setLocalStorageData(SELECT_BOARD, "dashboard");
    navigate("/dashboard");
  };

  React.useEffect(() => {
    GetSettingByKey();
  }, []);

  const GetSettingByKey = async () => {
    setSettingValueLoader(true);
    setDashboardLoader(true);
    dashboardService
      .GetSettingByKey()
      .then((response) => {
        if (response.statusCode === 200) {
          setSettingValueLoader(false);
          setSettingValue(response?.data?.SettingValue);
        } else {
          setSettingValueLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const DashboardCardBox = ({ item, navigationColor }) => {
    return (
      <Box className="Dashboard_Asset_Card_DR">
        <Grid container spacing={0}>
          <Grid item xs={7} sx={{ p: "5px" }}>
            {/* <Tooltip title={item?.CompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR oneLineCSS">
                {item?.CompanyName}
              </Box>
            </Tooltip> */}

            <Tooltip title={item?.CustomerCompanyName} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS mb-50 Bold_text">
                {item?.CustomerCompanyName || ""}
              </Box>
            </Tooltip>
            <Tooltip title={item?.DeliveryLocationName} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR Bold_text oneLineCSS text-black">
                {}
              </Box>
            </Tooltip>

            <Tooltip title={item?.UnitNumber} placement="top">
              <Box className="text-center Dashboard_Asset_Card_Text_DR_Center oneLineCSS">
                {item?.UnitNumber || ""}
              </Box>
            </Tooltip>

            <Tooltip title={item?.Model + " " + item?.MakeName} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS">
                {item?.Model + " " + item?.MakeName || ""}
              </Box>
            </Tooltip>

            <Tooltip title={item?.SerialNumber} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS">
                {item?.SerialNumber || ""}
              </Box>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{ background: "#f5f5f5", p: "5px" }}
            className="Dashboard_Asset_Card_Text_Highlight"
          >
            <Tooltip title={"Delivery To Site"} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {"Delivery To Site"}
              </Box>
            </Tooltip>
            <Tooltip title={item?.DeliveryLocationName} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR Bold_text oneLineCSS text-black">
                {item?.DeliveryLocationName || ""}
              </Box>
            </Tooltip>

            <Tooltip title={"Delivery Address"} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {"Delivery Address"}
              </Box>
            </Tooltip>
            <Tooltip title={item?.DeliveryAddress1} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {item?.DeliveryAddress1 || ""}
              </Box>
            </Tooltip>

            <Tooltip title={item?.DeliveryAddress2} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {item?.DeliveryAddress2 || ""}
              </Box>
            </Tooltip>

            <Tooltip
              title={item?.DeliveryCity + " " + item?.DeliveryCountry}
              placement="top"
            >
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {item?.DeliveryCity + " " + item?.DeliveryCountry || ""}
              </Box>
            </Tooltip>
            <Tooltip title={item?.DeliveryPostalCode} placement="top">
              <Box className="Dashboard_Asset_Card_Text_DR_Center oneLineCSS text-black">
                {item?.DeliveryPostalCode || ""}
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const GetAssetStatuses = async (e) => {
    setLoader({ ...loader, AssetStatusesLoader: true });
    let AssetStatuses = [
      {
        AssetStatusId: 1,
        AssetStatusName: "Outgoing",
        Key: "Outbound",
        SortOrder: 10,
        navigationColor: "#863B96",
        show: true,
      },
      {
        AssetStatusId: 2,
        AssetStatusName: "Incoming",
        Key: "Inbound",
        SortOrder: 10,
        navigationColor: "#00b33c",
        show: true,
      },
    ];

    setAssetStatuses(AssetStatuses);
    setAssetDisplayStatues(AssetStatuses);
    setLoader({ ...loader, AssetStatusesLoader: false });
  };

  const GetTierWiseAssetCategorizationSummaryDMS = async () => {
    setAssetCategorizationSummaryDMSLoader(true);
    dashboardService
      .GetTierWiseAssetCategorizationSummaryDMSAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setAssetCategorizationSummaryDMSLoader(false);
          setAssetTypeList(response.data);
        } else {
          setAssetCategorizationSummaryDMSLoader(false);
          if (response.statusCode === 401) {
            logOut();
          }
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  // ---------------------------------------------

  const handelSelectAssetTypeList = async (item, Type) => {
    resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        ShowBackBTN: true,
      });
    } else if (Type?.Type === "CategoryDetails") {
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };

  const handelSelectAssetTypeListBackFun = () => {
    if (
      assetList?.selectAssetDetails &&
      Object.keys(assetList?.selectAssetDetails).length !== 0 &&
      assetList?.tierCount === 0
    ) {
      setAssetList({
        ...assetList,

        ShowTitle: "",
        selectAssetDetails: {},
        ShowBackBTN: false,
      });
    } else if (assetList?.tierCount !== 0) {
      switch (assetList?.tierCount) {
        case 0:
          return setAssetList({
            ...assetList,
            ShowTitle: "",
            CategoryDetails: {},
            Tier1Select: {},
            Tier1Details: [],
            TierData: false,
          });
        case 1:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.selectAssetDetails?.AssetTypeName,
            Tier1Select: {},
            CategoryDetails: {},
            Tier2Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: false,
          });
        case 2:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.CategoryDetails?.CategoryName,
            Tier2Select: {},
            Tier1Select: {},
            Tier3Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 3:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier1Select?.Tier1Name,
            Tier3Select: {},
            Tier2Select: {},
            Tier4Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        case 4:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier2Select?.Tier2Name,
            Tier4Select: {},
            Tier3Select: {},
            Tier5Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });

        case 5:
          return setAssetList({
            ...assetList,
            ShowTitle: assetList?.Tier3Select?.Tier3Name,
            Tier4Select: {},
            Tier6Details: [],
            tierCount: assetList?.tierCount - 1,
            TierData: true,
          });
        default:
          return;
      }
    }
  };

  const GetStores = () => {
    dashboardService
      .GetStoresAPI()
      .then((response) => {
        if (response.statusCode === 200) {
          setStoreList(response.data);
        }
      })
      .catch((error) => console.log("Login Api Error =====", error));
  };

  const GetPagedAssets = (newPageDetails) => {
    setDashboardLoader(true);
    handleStop();
    let AssetStatuses = [];
    let AssetsPagination = [];
    let maxLength = 0;
    let recodeCount = {};
    if (!loader?.AssetStatusesLoader) {
      assetStatuses?.map((assetItem, index) => {
        if (assetItem?.show) {
          setLoader({ ...loader, [assetItem.AssetStatusName]: true });
          let payLoead = {
            Filter: {
              ContractNumber: null,
              EquipmentCategory: null,
              ShipperCompanyNumber: null,
              ShipperCompanyName: null,
              ShipperFirstName: null,
              ShipperLastName: null,
              ShipperEmail: null,
              CustomerCompanyNumber: null,
              CustomerCompanyName: null,
              ReceiptCustomerCompanyName: null,
              UnitNumber: null,
              MakeName: null,
              AssetYear: null,
              Model: null,
              SerialNumber: null,
              AssetSMU: null,
              ReceiptDateTime: null,
              ReceiptStatus: ["Pending"],
              Source: null,
              StoreId: null,
              All: null,
              Direction: [assetItem.Key],
            },
            IncludeCount: true,
            PageSize: showAssetCount,
            PaginationOperator: "AND",
            PageNumber:
              newPageDetails?.Outgoing?.PageDetails?.PageNumber || currentPage,
            Role: "SalesManager",
            Sort: "",
          };

          let loaders = loader;

          loaders = { ...loaders, [assetItem.AssetStatusName]: true };
          setLoader(loaders);
          dashboardService
            .GetPagedDeliveryReceiptSummaryAPI(payLoead)
            .then((response) => {
              if (response.statusCode === 200) {
                loaders = { ...loaders, [assetItem.AssetStatusName]: false };
                setLoader(loaders);
                if (maxLength < response?.length?.TotalPages) {
                  maxLength = response?.length?.TotalPages;
                }
                setTotalPage(maxLength);
                recodeCount = {
                  ...recodeCount,
                  [assetItem.AssetStatusName]: {
                    totalAssetsRecode: response?.length?.TotalRecords,
                  },
                };
                AssetStatuses = {
                  ...AssetStatuses,
                  [assetItem.AssetStatusName]: {
                    data: response?.data,
                    totalCount: response?.data?.length,
                    start: 0,
                    end: showAssetCount,
                  },
                };

                AssetsPagination = {
                  ...AssetsPagination,

                  [assetItem.AssetStatusName]: {
                    PageDetails: response?.length,
                  },
                };
                setAssetStatusesListPagination(AssetsPagination);
                setAssetStatusesDetails(AssetStatuses);
                setTotalAssetsRecode(recodeCount);

                if (assetStatuses?.length === index + 1) {
                  setDashboardLoader(false);
                  handleStart();
                }
              } else {
                setLoader({ ...loader, [assetItem.AssetStatusName]: false });
                if (response.statusCode === 401) {
                  logOut();
                }
              }
            })
            .catch((error) => console.log("Login Api Error =====", error));
          setLoader({ ...loader, [assetItem.AssetStatusName]: false });
        } else {
          if (assetStatuses?.length === index + 1) {
            setDashboardLoader(false);
            handleStart();
          }
        }
      });
    }
  };
  // ----------------------------------------------

  const logOut = () => {
    navigate("/");
    localStorage.removeItem("ACCESS_TOKENS");
    localStorage.removeItem("StoreCode");
    localStorage.removeItem("DisplayStatues-V1");
    localStorage.removeItem("rowPerPage");
    localStorage.removeItem("rowPerPageInDR");
    localStorage.removeItem("DisplayStatuesInDR");
    localStorage.removeItem("StoreCodeInDR");
    localStorage.removeItem("SELECT_BOARD");
  };

  React.useEffect(() => {
    GetAssetStatuses();
    GetTierWiseAssetCategorizationSummaryDMS();
  }, []);

  React.useEffect(() => {
    setDashboardLoader(true);
    if (!settingValueLoader) {
      GetPagedAssets();
    }
  }, [
    storeCodeSelect,
    showAssetCount,
    settingValueLoader,
    loader?.AssetStatusesLoader,
    assetStatuses,
    assetList?.selectAssetDetails?.AssetTypeId,
    assetList?.CategoryDetails?.CategoryName,
    assetList?.Tier1Select?.Tier1Name,
    assetList?.Tier2Select?.Tier2Name,
    assetList?.Tier3Select?.Tier3Name,
    assetList?.Tier4Select?.Tier4Name,
  ]);

  React.useEffect(() => {
    GetStores();
  }, []);
  const handelpagination = () => {
    if (!dashboardLoader) {
      let newPageDetails = [];
      if (totalPage > currentPage) {
        assetStatuses?.map((assetItem) => {
          // if (
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.PageNumber <
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.TotalPages
          // ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber:
                  assetStatusesListPagination?.[assetItem.AssetStatusName]
                    ?.PageDetails?.PageNumber + 1,
              },
            },
          };
          // } else {
          //   newPageDetails = {
          //     ...newPageDetails,
          //     [assetItem.AssetStatusName]:
          //       assetStatusesListPagination?.[assetItem.AssetStatusName],
          //   };
          // }
        });

        setCurrentPage(currentPage + 1);
      } else {
        assetStatuses?.map((assetItem) => {
          // if (
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.PageNumber <
          //   assetStatusesListPagination?.[assetItem.AssetStatusName]
          //     ?.PageDetails?.TotalPages
          // ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber: 1,
              },
            },
          };
          // }
        });

        setCurrentPage(1);
      }
      setAssetStatusesListPagination(newPageDetails);
      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
      setTimeAutoNextPage(!timeAutoNextPage);
    }
  };
  const handelpaginationBack = () => {
    let newPageDetails = [];
    if (currentPage > 1) {
      assetStatuses?.map((assetItem) => {
        if (
          assetStatusesListPagination?.[assetItem.AssetStatusName]?.PageDetails
            ?.PageNumber -
            1 >
          0
        ) {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]: {
              ...assetStatusesListPagination?.[assetItem.AssetStatusName],
              PageDetails: {
                ...assetStatusesListPagination?.[assetItem.AssetStatusName]
                  ?.PageDetails,
                PageNumber:
                  assetStatusesListPagination?.[assetItem.AssetStatusName]
                    ?.PageDetails?.PageNumber - 1,
              },
            },
          };
        } else {
          newPageDetails = {
            ...newPageDetails,
            [assetItem.AssetStatusName]:
              assetStatusesListPagination?.[assetItem.AssetStatusName],
          };
        }
      });
      setAssetStatusesListPagination(newPageDetails);
      setCurrentPage(currentPage - 1);

      setDashboardLoader(true);
      GetPagedAssets(newPageDetails);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelSelectStatuesModel = () => {
    setAssetDisplayStatues(assetStatuses);
    setSelectStatuesModel(false);
  };

  const handelSaveSelectStatuesModel = () => {
    localStorage.setItem(
      "DisplayStatuesInDR",
      JSON.stringify(assetDisplayStatues)
    );
    setDisplayStatuesLocalStorage(assetDisplayStatues);
    setAssetStatuses(assetDisplayStatues);
    setSelectStatuesModel(false);
  };
  const nextBtnRef = React.useRef();

  const resetPagination = () => {
    let newPageDetails = [];
    assetStatuses?.map((assetItem) => {
      newPageDetails = {
        ...newPageDetails,
        [assetItem.AssetStatusName]: {
          ...assetStatusesListPagination?.[assetItem.AssetStatusName],
          PageDetails: {
            ...assetStatusesListPagination?.[assetItem.AssetStatusName]
              ?.PageDetails,
            PageNumber: 1,
          },
        },
      };
    });
    setCurrentPage(1);
    setAssetStatusesListPagination(newPageDetails);
    return newPageDetails;
  };

  const handleStart = (autoPageNext) => {
    if (autoNextPage || autoPageNext) {
      const id = setInterval(() => {
        nextBtnRef.current?.click();
      }, 60000);
      setIntervalId(id);
    }
  };

  const handleStop = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  const handleStartAutoPageNavigation = () => {
    if (autoNextPage) {
      setAutoNextPage(false);
      handleStop();
    } else {
      setAutoNextPage(true);
      handleStart({ autoPageNext: true });
    }
  };

  const handelAssetDisplayStatuesChange = (index) => {
    let newStatuesValue = [];
    assetDisplayStatues?.map((assetItem, assetIndex) => {
      if (index === assetIndex) {
        let item = assetItem;
        item = { ...item, show: !assetItem.show };
        newStatuesValue.push(item);
      } else {
        newStatuesValue.push(assetItem);
      }
    });
    setAssetDisplayStatues(newStatuesValue);
  };

  const handelChangeRowParPageValue = async (e) => {
    localStorage.setItem("rowPerPageInDR", e.target.value);
    setShowAssetCount(e.target.value);

    await resetPagination();
  };

  const handelStoreSelect = async (e) => {
    setStoreCodeSelect(e.target.value);
    await resetPagination();
    localStorage.setItem("StoreCodeInDR", e.target.value);
  };
  return (
    <>
      <Modal
        open={selectStatuesModel}
        onClose={handelSelectStatuesModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ color: "#fff", fontWeight: "bold" }}
          >
            Display Statues
          </Typography>
          <FormGroup>
            {assetDisplayStatues?.map((assetItem, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={assetItem?.show}
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: "#fff",
                        },
                      }}
                      onChange={() => handelAssetDisplayStatuesChange(index)}
                    />
                  }
                  label={
                    <Box sx={{ color: "#fff" }}>
                      {assetItem?.AssetStatusName}
                    </Box>
                  }
                />
              );
            })}
          </FormGroup>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                marginRight: "10px",
              }}
              onClick={handelSaveSelectStatuesModel}
            >
              Save
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
              }}
              onClick={handelSelectStatuesModel}
            >
              Cancal
            </button>
          </Box>
        </Box>
      </Modal>
      <Box className="Dashboard_Box">
        {/* ------------------ small Screen To Md Screen design --------------- */}
        <Box className="Dashboard_Sm_Screen">
          <Box className="Dashboard_Right_Navigation_Bar">
            <Box className="Dashboard_Navigation_Card_Box">
              {AssetTypeList?.map((item, index) => {
                return (
                  <>
                    <Accordion
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                      sx={{ background: "#ff000000" }}
                    >
                      <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                        sx={{ margin: "0px", padding: "0px" }}
                      >
                        <Box
                          className="Dashboard_Navigation_Card_Sm"
                          sx={{ background: item?.AssetColorCode }}
                        >
                          <Box className="Dashboard_Navigation_Card_text">
                            {item?.AssetTypeName}
                          </Box>
                          <Box className="Dashboard_Navigation_Card_text text-bold">
                            {item?.AssetCount}
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {AssetList?.map((AssetListItem) => {
                          return (
                            AssetListItem?.Type === item?.AssetTypeName && (
                              <DashboardCardBox item={AssetListItem} />
                            )
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
            </Box>
            <Box className="Dashboard_Navigation_Setting_Box">
              <Box className="Dashboard_Navigation_Page_Text">
                Page{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  10
                </span>{" "}
                of{" "}
                <span className="Dashboard_Navigation_Page_Text_Highlights">
                  59
                </span>
              </Box>
              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <WestIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <PauseIcon />
                </Box>
                <Box className="Dashboard_Navigation_Button cursor_pointer">
                  <EastIcon />
                </Box>
              </Box>

              <Box className="Dashboard_Navigation_Button_Box">
                <Box className="Dashboard_Option_close_Button">
                  <Box className="Dashboard_Navigation_close_Button">
                    <SettingsIcon sx={{ fontSize: "16px" }} />
                  </Box>
                  <span style={{ marginLeft: "5px" }}>Settings</span>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* ------------------ md To Big Screen design --------------- */}
        <Box className="Dashboard_Big_Screen" sx={{ paddingTop: "20px" }}>
          <Grid container spacing={1}>
            <Grid item xs={showSideBar ? 10 : 12}>
              <div
                className="dashboard_Box"
                style={{ display: "flex", overflow: "auto", width: "100%" }}
              >
                {dashboardLoader ? (
                  <Box
                    sx={{
                      height: "90vh",
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomLoader />
                  </Box>
                ) : (
                  assetStatuses?.map((assetItem) => {
                    return (
                      assetItem?.show && (
                        <Box
                          sx={{
                            mb: "60px",
                            minWidth: "50%",
                            borderRight: "1px solid #000",
                          }}
                        >
                          <Box>
                            <Box className="Dashboard_Asset_Heading">
                              {assetItem?.AssetStatusName} (
                              {
                                totalAssetsRecode?.[assetItem?.AssetStatusName]
                                  ?.totalAssetsRecode
                              }
                              )
                            </Box>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                padding: "0 20px",
                              }}
                            >
                              {assetStatusesDetails?.[
                                assetItem?.AssetStatusName
                              ]?.data.map((item) => {
                                return (
                                  <Grid item xs={12}>
                                    <DashboardCardBox
                                      item={item}
                                      navigationColor={
                                        assetItem?.navigationColor
                                      }
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>
                        </Box>
                      )
                    );
                  })
                )}
              </div>
            </Grid>
            {showSideBar && (
              <Grid item xs={2} className="Dashboard_Right_Navigation_Bar">
                <Box
                  className="Dashboard_Navigation_Card_Box"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <img src="./logo.png" alt="" className="logo-light" />
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Tooltip title={"Switch to Rental Board"} placement="top">
                        <Box
                          mr={"20px"}
                          onClick={() => setOpenSwitchModel(true)}
                          sx={{ cursor: "pointer" }}
                          width={"2vw"}
                          height={"2vw"}
                          padding={"5px"}
                          bgcolor={"#ff0000"}
                          borderRadius={"0.5vw"}
                        >
                          <img src={RNONE} width={"100%"} />
                        </Box>
                      </Tooltip>

                      <Tooltip title={"Log out"} placement="top">
                        <Box
                          onClick={handleClickOpen}
                          sx={{ cursor: "pointer" }}
                          width={"2vw"}
                          height={"2vw"}
                          padding={"5px"}
                          bgcolor={"#ff0000"}
                          borderRadius={"0.5vw"}
                        >
                          <img src={LogOutIcon} width={"100%"} />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box>
                      {/* <Box className="Dashboard_Navigation_Header">
                          <Box
                            className="Dashboard_Navigation_Back_Btn"
                            style={{ opacity: "0.5", cursor: "not-allowed" }}
                            onClick={handelSelectAssetTypeListBackFun}
                          >
                            <ArrowBackIcon />
                          </Box>
                        </Box> */}
                    </Box>
                  </Box>
                  <Box>
                    <Box>
                      <Box sx={{ textAlign: "start", color: "#fff" }}>
                        Recoeds per Column
                      </Box>
                      <Box className="custom-select">
                        <select
                          className="select_Box"
                          value={showAssetCount}
                          onChange={handelChangeRowParPageValue}
                        >
                          <option className="select_option" value="10">
                            10
                          </option>
                          <option className="select_option" value="20">
                            20
                          </option>
                          <option className="select_option" value="50">
                            50
                          </option>
                          <option className="select_option" value="100">
                            100
                          </option>
                        </select>
                      </Box>
                    </Box>
                    <Box
                      className="Dashboard_Navigation_Card"
                      sx={{ background: "red", justifyContent: "center" }}
                      onClick={() => setSelectStatuesModel(true)}
                    >
                      <Box
                        className="Dashboard_Navigation_Card_text"
                        sx={{ color: "#fff", cursor: "pointer" }}
                      >
                        Select Statuses
                      </Box>
                    </Box>
                    <Box
                      className="Dashboard_Navigation_Card"
                      sx={{ background: "red", justifyContent: "center" }}
                      onClick={() => setOpenSwitchModel(true)}
                    >
                      <Box
                        className="Dashboard_Navigation_Card_text"
                        sx={{
                          color: "#fff",
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <img
                          src="./switch.svg"
                          alt=""
                          width={"20px"}
                          style={{ marginRight: "10px" }}
                        />
                        Switch to Rental Board
                      </Box>
                    </Box>
                    <Box
                      className="Dashboard_Navigation_Card"
                      sx={{ background: "red", justifyContent: "center" }}
                      onClick={handleClickOpen}
                    >
                      <Box
                        className="Dashboard_Navigation_Card_text"
                        sx={{ color: "#fff", cursor: "pointer" }}
                      >
                        Log Out
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>

        {/*  --------------- Footer ------------------- */}
        <Box className="DashboardComponent_Footer">
          <Grid container spacing={0} sx={{ alignItems: "center" }}>
            <Grid item xs={10}>
              <Box className="Dashboard_Navigation_Setting_Box_Big_Screen">
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  <Box
                    className="Dashboard_Navigation_Button cursor_pointer"
                    onClick={handleStartAutoPageNavigation}
                  >
                    {autoNextPage ? <PauseIcon /> : <PlayArrowIcon />}
                  </Box>
                </Box>
                <Box className="Dashboard_Navigation_Button_Box_Big_Screen">
                  <Box
                    className="Dashboard_Navigation_Button cursor_pointer"
                    onClick={handelpaginationBack}
                  >
                    <KeyboardArrowLeftIcon />
                  </Box>
                  <Box className="Dashboard_Navigation_Page_Text">
                    Page{" "}
                    <span className="Dashboard_Navigation_Page_Text_Highlights">
                      {currentPage}
                    </span>{" "}
                    of{" "}
                    <span className="Dashboard_Navigation_Page_Text_Highlights">
                      {totalPage}
                    </span>
                  </Box>
                  <Box
                    className="Dashboard_Navigation_Button cursor_pointer"
                    onClick={handelpagination}
                    ref={nextBtnRef}
                  >
                    <KeyboardArrowRightIcon />
                  </Box>
                </Box>
                <Box></Box>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box className="Dashboard_Option_close_Button" />
                {!!showSideBar && <Box></Box>}
                {showSideBar ? (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowRightIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      className="Dashboard_Option_close_Button"
                      onClick={() => setShowSideBar(!showSideBar)}
                    >
                      <Box
                        className="Dashboard_Navigation_close_Button"
                        sx={{ border: "none", background: "#22272B" }}
                      >
                        <KeyboardArrowLeftIcon sx={{ fontSize: "25px" }} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openSwitchModel}
          onClose={() => setOpenSwitchModel(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to switch to Rental Board ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={() => setOpenSwitchModel(false)}
            >
              No
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={Dashboard_Navigate}
            >
              Yes
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to logout ?"}
          </DialogTitle>
          <DialogActions>
            <button
              style={{
                background: "#f2f2f2",
                color: "#000",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid #f2f2f2",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={handleClose}
            >
              Cancal
            </button>
            <button
              style={{
                background: "red",
                color: "#fff",
                padding: "15px 40px",
                borderRadius: "5px",
                border: "1px solid red",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={logOut}
            >
              Log Out
            </button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default DeliveryReceiptComponent;
